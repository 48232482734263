<template>
    <div class="wrapper m_wrap m_contact">
        <m_head></m_head>
        <!--        head end-->
        <m_menu activeClassId="6"></m_menu>
        <!--        m_menu end-->
        <div class="m_contact_banner">
            <img src="../../../assets/images/mobile/contact/banner.png" alt="">
        </div>
<!--        m_contact_banner end-->

        <div class="m_contact_cont">
            <div class="m_contact_cont_tit">
                客服中心
                <p class="m_contact_cont_tit_span">
                    为您提供卓越的技术支持和服务保障
                </p>
            </div>
            <div class="m_contact_cont_list">
                <p>联系方式：400-808-1710 <a href="tel: 400-808-1710"><i class="el-icon-phone-outline"></i></a></p>
                <p>公司网址：http://www.yixingauto.com/ <a href="http://www.yixingauto.com/" target="_blank"><i class="el-icon-bangzhu"></i></a></p>
                <p>联系邮箱：wangxinpei@yzxauto.com <a href="mailto: wangxinpei@yzxauto.com"><i class="el-icon-message"></i></a></p>
            </div>

            <div class="m_contact_line"></div>

            <div class="m_contact_cont_tit">
                商务合作
                <p class="m_contact_cont_tit_span">
                    成为我们的伙伴解锁更多相关服务权限
                </p>
            </div>
            <ul class="m_contact_tab">
                <li v-for="(item,index) in contact_data"
                    :key="index"
                    @click="change_contact(index)"
                    :class="isContact == index? 'cur':''"
                >{{item.label}}</li>
            </ul>
            <div class="m_contact_tab_item" v-if="isContact == 0">
                <div class="m_contact_tab_item_l">
                    <p>联系人：赵经理</p>
                    <p>手机号：180-3200-9908</p>
                </div>
                <div class="m_contact_tab_item_r">
                    <img src="../../../assets/images/mobile/about/about_cont_erm_z.png">
                </div>
            </div>
            <div class="m_contact_tab_item" v-if="isContact == 1">
                <div class="m_contact_tab_item_l">
                    <p>联系人：王经理</p>
                    <p>手机号：186-3211-8016</p>
                </div>
                <div class="m_contact_tab_item_r">
                    <img src="../../../assets/images/mobile/about/about_cont_ewm_w.png">
                </div>
            </div>

        </div>



        <m_btm></m_btm>
    </div>
</template>

<script>
    import m_head from "../components/head";
    import m_menu from "../components/menu";
    import m_btm from "../components/bottom";

    let contact_data = [
        {
            id: 1,
            label: '经销商入驻咨询',
            info: [
                {
                    name: '赵经理',
                    mobile: '180-3200-9908',
                    img: require('../../../assets/images/mobile/about/about_cont_erm_z.png')
                }
            ]
        },
        {
            id: 2,
            label: '推广代运营咨询',
            info: [
                {
                    name: '王经理',
                    mobile: '186-3211-8016',
                    img: require('../../../assets/images/mobile/about/about_cont_ewm_w.png')
                }
            ]
        }
    ];
    export default {
        name: "contact",
        components:{
            m_head,
            m_menu,
            m_btm
        },
        data(){
            return{
                contact_data: contact_data,
                isContact: 0,
            }
        },
        methods:{
            change_contact(id){
                this.isContact = id;
            },

        }
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/mobile/contact/contact";
</style>
