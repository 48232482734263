<template>
    <div class="wrapper pc_verification">
        <pc_top></pc_top>
        <!--        pc_top end-->
        <div class="pc_verification_banner">
            <img :src="this.banner" alt="易行汽车" v-if="this.banner">
            <img src="../../../assets/images/pc/verification/banner.jpg" alt="暂无图片" v-else>
        </div>
        <!--        pc_verification_banner end-->

        <el-form
                :model="action_form"
                :rules="rules"
                ref="action_form"
                hide-required-asterisk
                class="pc_verification_form"
        >
            <div class="verification_form_wrap">
                <el-form-item class="verification_input">
                    <el-select
                            v-model="car_id"
                            placeholder="请选择车型"
                            filterable
                            clearable
                            style="width: 100%;"
                            @change="change_car"
                            value-key="id"
                    >
                        <el-option
                                v-for="item in cars_list"
                                :key="item.id"
                                :label="item.name"
                                :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="name" class="verification_input">
                    <el-input
                            v-model="action_form.name"
                            placeholder="请输入姓名"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="phone" class="verification_input">
                    <el-input
                            v-model="action_form.phone"
                            placeholder="请输入电话"
                            type="number"
                    ></el-input>
                </el-form-item>
            </div>

            <el-form-item class="verification_btn">
                <el-button @click="submitForm()">立即预约</el-button>
            </el-form-item>
        </el-form>
        <!--        form end-->
        <div class="cars_tit" v-if="this.cars_list.length > 0">
            <img src="../../../assets/images/pc/verification/tit.jpg" alt="">
        </div>
        <!--        cars_tit end-->
        <ul class="cars_list">
            <li v-for="(item,index) in cars_list" :key="index">
                <img :src="item.thumb" :alt="item.name" v-if="item.thumb">
                <img src="../../../assets/images/pc/verification/car.png" alt="暂无图片" v-else>

                <h5>{{item.name}}</h5>
                <el-button @click="get_details(item)">了解详情</el-button>
            </li>
        </ul>
        <!--        cars_list end-->

        <div class="pc_verification_info_img">
            <img :src="this.info_img" alt="易行汽车" v-if="this.info_img">
            <img src="../../../assets/images/pc/verification/info_img.png" alt="默认图片" v-else>
        </div>
        <!--        info_img end-->

        <pc_bottom></pc_bottom>

        <el-dialog :visible.sync="dialogVisible_suc" width="30%">
            <img src="../../../assets/images/pc/verification/suc.png" alt="成功">
        </el-dialog>

    </div>
</template>

<script>
    import pc_top from "../components/top";
    import pc_bottom from "../components/bottom";
    import {isPhone} from '../../../common/_validate';
    import lib_activity from "../../../libs/lib_activity";
    import _geturl from "../../../utils/_geturl";
    import _object from "../../../utils/_object";
    import _wx from "../../../common/_wx";
    import { Message } from "element-ui";
    import _request from "../../../common/_request";


    let action_form = {
        brand_id: 0,
        automaker_id: 0,
        series_id: 0,
        name: "",
        phone: "",
    };

    export default {
        name: "brand_sign",
        components:{
            pc_top,
            pc_bottom
        },
        data(){
            return{
                adv_id: 0,
                banner: '',
                info_img: '',
                btm_img: '',
                adv_name: '',

                //
                action_form:action_form,
                rules: {
                    name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
                    phone: [
                        { required: true, message: "请输入电话", trigger: "blur" },
                        { required: true, validator: isPhone, trigger: "blur" },
                    ],
                }, //验证

                //品牌车型
                car_id: {id: 0},
                cars_list: [],

                //
                dialogVisible_suc: false,
            }
        },
        created() {
            this.adv_id = _geturl('adv_id');
            lib_activity.info(this.adv_id, this.info);
        },
        methods: {
            //页面信息
            info(data){
                document.title = data.name;
                this.banner = data.thumb;
                this.info_img = data.info_pc;
                this.btm_img = data.tail_pc;
                this.cars_list = data.list;

                if(data.hasOwnProperty('wx_share') && _object._length(data.wx_share)){
                    let options = {}
                    options.title = data.name
                    options.link = window.location.href;
                    options.image = window.location.protocol + '//' + window.location.host + data.thumb;

                    _wx.js_api(data.wx_share, _wx.share, options);
                }
            },
            //选车型
            change_car(val){
                this.action_form.series_id = val.id;
                this.action_form.automaker_id = val.automaker_id;
                this.action_form.brand_id = val.brand_id;
            },
            //清空
            clear_form(){
                this.action_form.brand_id = 0;
                this.action_form.brand_idautomaker_id = 0;
                this.action_form.series_id = 0;
                this.action_form.name = '';
                this.action_form.phone = '';
                this.car_id = {id: 0};
            },
            //提交表单
            submitForm(){
                let that = this;
                if (!this.action_form.series_id) {
                    Message.info("请选择车型");
                    return;
                }
                this.$refs.action_form.validate((valid) => {
                    if (valid){
                        let data = {
                            id : this.adv_id,
                            name: this.action_form.name,
                            mobile: this.action_form.phone,
                            brand_id: this.action_form.brand_id,
                            automaker_id: this.action_form.automaker_id,
                            series_id: this.action_form.series_id,
                            from_url: window.location.href
                        }
                        let params = _request._params('Common', 'advert_signup', data);
                        _request._post_base(params, function (response){
                            if (response.code !== _request.success) {
                                Message.info(response.info);
                                return;
                            }
                            Message.info(response.info);
                            that.dialogVisible_suc = true;
                            that.clear_form();
                        })
                    }
                })
            },

            //详情
            get_details(val){
                this.car_id = {id: val.id};
                this.action_form.series_id = val.id;
                this.action_form.automaker_id = val.automaker_id;
                this.action_form.brand_id = val.brand_id;

                document.documentElement.scrollTop = 300 ;
                document.body.scrollTop = 300;
            }

        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/pc/verification/verification";
</style>
<style lang="less">
    .pc_verification{
        .el-dialog{
            margin-top: 26vh;
            border-radius: 11px;
            .el-dialog__body{
                img{
                    display: block;
                    width: 100%;
                }
            }
        }
    }
</style>
