<template>
    <div class="pc_cars pc_wrap" style="padding-top: 220px;">
        <ul class="pc_cars_wrap">
            <li class="pc_cars_wrap_li">
                <div class="pc_cars_wrap_l">轿车</div>
                <ul class="pc_cars_wrap_r">
                    <li v-for="(item,index) in cars_adv_data" :key="index" @click="item.link? go_link(item.link): ''">
                        <p class="pc_cars_wrap_r_name">{{item.name}}</p>
                        <p class="pc_cars_wrap_r_param">报价 | 图片 | 配置</p>
                    </li>
                </ul>
            </li>

            <li class="pc_cars_wrap_li">
                <div class="pc_cars_wrap_l">SUV</div>
                <ul class="pc_cars_wrap_r">
                    <li v-for="(item,index) in suv_adv_data" :key="index" @click="item.link? go_link(item.link): ''">
                        <p class="pc_cars_wrap_r_name">{{item.name}}</p>
                        <p class="pc_cars_wrap_r_param">报价 | 图片 | 配置</p>
                    </li>
                </ul>
            </li>

            <li class="pc_cars_wrap_li">
                <div class="pc_cars_wrap_l">其他</div>
                <ul class="pc_cars_wrap_r">
                    <li v-for="(item,index) in other_adv_data" :key="index" @click="item.link? go_link(item.link): ''">
                        <p class="pc_cars_wrap_r_name">{{item.name}}</p>
                        <p class="pc_cars_wrap_r_param">报价 | 图片 | 配置</p>
                    </li>
                </ul>
            </li>


<!--            //-->
            <li class="pc_cars_wrap_li pc_brand_wrap">
                <div class="pc_cars_wrap_l" style="display: none">品牌</div>
                <ul class="pc_cars_wrap_r" style="width: 100%; margin-left: 0;">
                    <li v-for="(item,index) in brand_data" :key="index" @click="go_collection">
                        <img :src="item.img" :alt="item.name">
                        <p>{{item.name}}</p>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
    import lib_common from "../../../libs/lib_common";

    let cars_data = [
        {
            name: '轿车',
            child: [
                {
                    name: '轩逸',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963599_7861.png',
                },
                {
                    name: '卡罗拉',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963602_5012.png',
                },
                {
                    name: '雷凌',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963608_6545.png'
                },
                {
                    name: 'Model 3',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963605_118.png'
                },
                {
                    name: '凯美瑞',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963600_3222.png'
                },
                {
                    name: '朗逸',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963600_0594.png'
                },
                {
                    name: '宝来',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963602_8379.png'
                },
                {
                    name: '宝马3系',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963600_4295.png'
                }
            ]
        },
        {
            name: 'SUV',
            child: [
                {
                    name: '哈弗H6',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963600_2005.png',
                },
                {
                    name: '长安CS75 PLUS',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963601_2444.png',
                },
                {
                    name: '博越',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963607_0204.png'
                },
                {
                    name: 'RAV4荣放',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963603_4375.png'
                },
                {
                    name: '本田CR-V',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963602_73.png'
                },
                {
                    name: '缤智',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963608_9547.png'
                },
                {
                    name: '逍客',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963610_0925.png'
                },
                {
                    name: '昂科威',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963610_4691.png'
                }
            ]
        },
        {
            name: '其他',
            child: [
                {
                    name: '别克GL8',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963612_1654.png',
                },
                {
                    name: '传祺M8',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963611_8701.png',
                },
                {
                    name: '传祺M6',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963629_6186.png'
                },
                {
                    name: '艾力绅',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963603_4375.png'
                },
                {
                    name: '奥德赛',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963615_8841.png'
                },
                {
                    name: '菱智',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963662_3477.png'
                },
                {
                    name: '五菱凯捷',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963610_9739.png'
                },
                {
                    name: '上汽大通MAXUS',
                    img: 'https://www.yixingauto.com/uploads/thumb/1636963664_5499.png'
                }
            ]
        }
    ]
    let brand_data = [
        {
            name: '大众',
            img: 'https://www.yixingauto.com/uploads/logo/1638088110_9404.png'
        },
        {
            name: '丰田',
            img: 'https://www.yixingauto.com/uploads/logo/1638088116_3201.png'
        },
        {
            name: '比亚迪',
            img: 'https://www.yixingauto.com/uploads/logo/1638088102_3548.png'
        },
        {
            name: '奥迪',
            img: 'https://www.yixingauto.com/uploads/logo/1638088097_3485.png'
        },
        {
            name: '哈弗',
            img: 'https://www.yixingauto.com/uploads/logo/1638088123_2735.png'
        },
        {
            name: '吉利汽车',
            img: 'https://www.yixingauto.com/uploads/logo/1638088129_014.png'
        },
        {
            name: '特斯拉',
            img: 'https://www.yixingauto.com/uploads/logo/1638088161_7851.png'
        },
        {
            name: '宝马',
            img: 'https://www.yixingauto.com/uploads/logo/1638088101_5881.png'
        },
        {
            name: '领克',
            img: 'https://www.yixingauto.com/uploads/logo/1638088135_9442.png'
        },
        {
            name: '长安',
            img: 'https://www.yixingauto.com/uploads/logo/1638088107_9936.png'
        },
        {
            name: '本田',
            img: 'https://www.yixingauto.com/uploads/logo/1638088101_9462.png'
        },
        {
            name: '奔驰',
            img: 'https://www.yixingauto.com/uploads/logo/1638088100_9252.png'
        },
        {
            name: '捷达',
            img: 'https://www.yixingauto.com/uploads/logo/1638088129_9527.png'
        },
        {
            name: '凯迪拉克',
            img: 'https://www.yixingauto.com/uploads/logo/1638088133_0569.png'
        },
        {
            name: '荣威',
            img: 'https://www.yixingauto.com/uploads/logo/1638088153_1277.png'
        },
        {
            name: '五菱汽车',
            img: 'https://www.yixingauto.com/uploads/logo/1638088165_0872.png'
        }

    ]
    export default {
        name: "cars",
        data(){
            return{
                cars_data: cars_data,
                brand_data: brand_data,
                //
                cars_adv_data: [],
                suv_adv_data: [],
                other_adv_data: [],
            }
        },
        created() {
            this.cars_adv_list();
            this.suv_adv_list();
            this.other_adv_list();
        },
        methods:{
            cars_adv_list() {
                let that = this;
                lib_common.advert("11", "8", this.city_id, function (res) {
                    that.cars_adv_data = res.list;
                });
            },
            suv_adv_list() {
                let that = this;
                lib_common.advert("12", "8", this.city_id, function (res) {
                    that.suv_adv_data = res.list;
                });
            },
            other_adv_list(){
                let that = this;
                lib_common.advert("13", "8", this.city_id, function (res) {
                    that.other_adv_data = res.list;
                });
            },

            go_collection(){
                window.open('https://www.yixingauto.com/new_pages/autoshow?activity_id=138&channel=1&tids=');
            },
            //
            go_link(link){
                window.open(link);
            },
        }
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/pc/cars/cars";
</style>
