<template>
   <div class="wrapper pc_head">
       <div class="pc_head_l">
           <div class="pc_head_l_img"><img src="../../../assets/images/logo.png" alt=""></div>
           <div class="pc_head_l_city" @click="showcity()">
               <i class="el-icon-location-outline"></i>
               <span>{{ city_name }}</span>
           </div>
       </div>
       <div class="pc_head_r pc_head_search">
           <div class="pc_head_adv">
               <a
                       :href="head_advert.link?head_advert.link:'javascript:void(0)'"
                       :target="head_advert.link?'_blank':'_self'">
                  <img :src="head_advert.thumb" :alt="head_advert.name">
               </a>

           </div>
           <el-input
                   placeholder="请输入关键字"
                   prefix-icon="el-icon-search"
                   v-model="search_name"
                   clearable
           >
               <el-button slot="append" @click="getAuto()">搜索</el-button>
           </el-input>
       </div>

<!--       //城市-->
       <div class="pc_citys" v-if="citysdialog === true">
           <div class="pc_citys_wrap">
               <!-- // -->
               <template v-for="(city_list, letter) in citys">
                   <div class="pc_citys_directly" :key="letter">
                       <div class="pc_citys_dir_name1" :id="'letter_' + letter">{{ letter }}</div>
                       <div class="pc_citys_dir_list">
                           <div class="pc_citys_dir_list_ul">
                               <ul class="pc_citys_dir_list_info">
                                   <li
                                           v-for="city in city_list"
                                           :key="city.id"
                                           @click="getCity(city.id)"
                                   >
                                       {{ city.name }}
                                   </li>
                               </ul>
                           </div>
                       </div>
                   </div>
               </template>
               <!-- // -->
           </div>
       </div>
   </div>
</template>

<script>
    import _cache_city from "../../../cache/_cache_city";
    import lib_common from "../../../libs/lib_common";
    import _config from "../../../common/_config";

    export default {
        name: "pc_head",
        data(){
            return{
                //城市
                citysdialog: false,
                citys: [],
                city_id: 1301,
                city_name: "石家庄",
                //广告
                head_advert: '',
                //检索

                search_name: '',
            }
        },
        methods:{
            showcity() {
                this.citysdialog = !this.citysdialog;
            },
            getCity(id) {
                this.citysdialog = false;
                window.location.href =
                    window.location.protocol +
                    "//" +
                    window.location.host + '/app'+
                    _config.prefix +
                    "/home?city_id=" +
                    id;
            },
            renderCity() {
                lib_common.city(this.to_citys);
            },
            to_citys(data) {
                this.citys = data;
                for (let letter in data) {
                    for (let i in data[letter]) {
                        if (this.city_id == data[letter][i].id) {
                            this.city_name = data[letter][i].name;
                            return;
                        }
                    }
                }
            },
            //广告位
            adv_list(){
                let that = this;
                lib_common.advert("3", "1", this.city_id, function (res) {
                    that.head_advert = res.list[0];
                });
            },


            getAuto(){
                this.$router.push({name: 'auto',params:{search_name: this.search_name}});
            },

        },
        created() {
            this.city_id = _cache_city.get();
            this.renderCity();
            this.adv_list();
        },
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/pc/components/head";
@import "../../../assets/style/pc/components/citys";
</style>
<style lang="less">
    /*重写搜索框*/
    .pc_head_search{
        .el-input-group{
            input{
                border: none !important;
            }
            i{
                color: #4775ff;
                font-weight: bold;
                font-size: 14pt;
            }
            .el-input-group__append{
                background: #4775ff;
                color: #fff;
                border: none;
            }
        }
    }
</style>
