import { render, staticRenderFns } from "./brand_sign.vue?vue&type=template&id=5dce96a2&scoped=true&"
import script from "./brand_sign.vue?vue&type=script&lang=js&"
export * from "./brand_sign.vue?vue&type=script&lang=js&"
import style0 from "./brand_sign.vue?vue&type=style&index=0&id=5dce96a2&scoped=true&lang=less&"
import style1 from "./brand_sign.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dce96a2",
  null
  
)

export default component.exports