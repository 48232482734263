<template>
    <div class="wrapper pc_index">
        <new_top :cenAdvlist_7="cenAdvlist_7" :cenAdvlist_8="cenAdvlist_8"></new_top>
<!--        pc_top end-->
        <cars_data></cars_data>
<!--        cars_data-->
        <div class="pc_banner pc_wrap">
            <div class="pc_banner_img">
                <div class="pc_banner_img_l">
                    <el-carousel :interval="5000" arrow="always">
                        <el-carousel-item
                                v-for="item in banners"
                                :key="item.id"
                                @click.native="item.link? linkTo(item.link): ''"
                        >
                            <img :src="item.thumb" :alt="item.name" />
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
<!--        pc_banner end-->
        <div class="pc_index_company">
            <div class="pc_index_company_wrap pc_wrap">
                <div class="pc_index_company_l animate__animated animate__slideInLeft">
                    <div class="pc_index_company_l_tit pc_index_tit">
                        易行  一家专业的汽车展会运营商
                    </div>
                    <div class="pc_index_company_l_cont">
                        易行汽车（河北易之行网络科技有限公司）成立于2017年，是一家专业
                        汽车展会运营商，截止目前，公司业务范围已经扩展至河北，山西，广东，
                        内蒙，湖北，四川，青海，甘肃，江西等多省份超八十个城市。
                    </div>
                    <div class="pc_index_company_l_num">
                        <ul class="pc_index_company_l_num_ul">
                            <li v-for="(item,index) in companylist" :key="index" class="pc_index_company_l_num_ul_li">
                                <div class="pc_index_company_l_num_icon">
                                    <img :src="item.icon" alt="">
                                </div>
                                <div class="pc_index_company_l_num_info">
                                    <b><CountUp :endVal="item.num" :delay="delay" :options="options" />{{item.unit}}</b>
                                    <p>{{item.info}}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <el-button type="primary" class="pc_index_company_l_btn" @click="linkToLocal('about')">
                       了解更多
                    </el-button>
                    </div>
                <div class="pc_index_company_r animate__animated animate__slideInRight">
                     <img src="../../../assets/images/pc/index/pc_index_map.png" alt="">
                 </div>
            </div>
        </div>
<!--        pc_company end-->

        <div class="pc_index_advert pc_wrap">
            <a :href="cenAdvlist_6.link?cenAdvlist_6.link:'javascript:void(0)'"
               :target="cenAdvlist_6.link?'_blank':'_self'">
                <img :src="cenAdvlist_6.thumb" :alt="cenAdvlist_6.name">
            </a>
        </div>
        <!--        pc_index_advert end-->

        <div class="pc_index_recommend pc_wrap">
            <div class="pc_index_tit">车型推荐</div>
            <div class="pc_index_recommend_top">
                <div class="pc_index_recommend_l">
                    <video src="../../../assets/images/pc/video/yingbao.mp4" :controls="videoOptions.controls" width="100%"></video>
                    <div class="pc_index_recommend_l_tit">【师妹带你看车啦】广汽传祺-影豹 <span>指导价：<b>9.83-13.68万元</b></span></div>
                </div>
                <div class="pc_index_recommend_r">
                    <div class="pc_index_recommend_r_tit">本地热门车型</div>
                    <ul class="pc_index_recommend_r_ul">
                        <li v-for="(item,index) in hot_cars" :key="index">
                            <div class="pc_index_recommend_r_ul_image"><img :src="item.img" :alt="item.name"></div>
                            <div class="pc_index_recommend_r_ul_price">{{item.name}}
                                <p>指导价
                                <span>{{item.price}}</span>
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
<!--        pc_index_recommend end-->
        <div class="pc_index_cbh pc_wrap">
            <router-link tag="div" :to="{name: 'info', query:{id:item.id}}" class="pc_index_cbh_list" v-for="(item,index) in cbh_news_list" :key="index">
                <div class="pc_index_cbh_list_img">
                    <img :src="item.thumb" :alt="item.name">
                </div>
                <p class="pc_index_cbh_list_p single_line">{{item.name}}</p>
            </router-link>
        </div>
<!--        pc_index_cbh end-->
        <div class="pc_index_advert_double pc_wrap">
            <a :href="cenAdvlist_5.link?cenAdvlist_5.link:'javascript:void(0)'"
               :target="cenAdvlist_5.link?'_blank':'_self'" class="pc_index_advert_double_one">
                <img :src="cenAdvlist_5.thumb" :alt="cenAdvlist_5.name">
            </a>
            <a :href="cenAdvlist_4.link?cenAdvlist_4.link:'javascript:void(0)'"
               :target="cenAdvlist_4.link?'_blank':'_self'" class="pc_index_advert_double_two">
                <img :src="cenAdvlist_4.thumb" :alt="cenAdvlist_4.name">
            </a>
        </div>
<!--        pc_index_advert end-->

        <div class="pc_index_auto pc_wrap">
            <div class="pc_index_tit">易行车展</div>
            <div class="pc_index_auto_list">
                <a
                        class="pc_index_auto_info"
                        v-for="(item, index) in autolist"
                        :key="index"
                        :href="item.link?item.link:'javascript:void(0)'"
                        :target="item.link?'_blank':'_self'"
                >

                    <div class="pc_index_auto_info_img">
                        <img :src="item.thumb" :alt="item.name">
                    </div>
                    <div class="pc_index_auto_info_name">
<!--                        <p>{{ timedata(item.stime) }} 至 {{ timedata(item.etime) }}</p>-->
                        <p class="single_line">{{ item.name }}</p>
                    </div>
                    <div class="top_right_sub jxz" v-if="nowtime <= item.etime">进行中</div>
                    <div class="top_right_sub yjs" v-else>已结束</div>

                </a>
            </div>
            <div class="pc_index_more" style="display: none;">
                <router-link :to="{name: 'auto'}" target="_blank" class="pc_index_more_link">更多城市展会信息</router-link> <i class="el-icon-arrow-right"></i><i class="el-icon-arrow-right"></i><i class="el-icon-arrow-right"></i>
            </div>
        </div>
<!--        pc_index_auto end-->

        <div class="pc_index_advert pc_wrap" style="margin: 0 auto;">
            <img src="../../../assets/images/pc/index/pc_index_adv.jpg" alt="">
        </div>
        <!--        pc_index_advert end-->

        <div class="pc_index_video pc_wrap">
            <div class="pc_index_video_wrap" v-for="(item,index) in video_list" :key="index">
                <video :src="item.url" :controls="videoOptions.controls" width="100%"></video>
            </div>
        </div>
<!--        pc_index_video end-->



        <div class="pc_index_advert_double pc_wrap">
            <a :href="cenAdvlist_3.link?cenAdvlist_3.link:'javascript:void(0)'"
               :target="cenAdvlist_3.link?'_blank':'_self'" class="pc_index_advert_double_two">
                <img :src="cenAdvlist_3.thumb" :alt="cenAdvlist_3.name">
            </a>
            <a :href="cenAdvlist_2.link?cenAdvlist_2.link:'javascript:void(0)'"
               :target="cenAdvlist_2.link?'_blank':'_self'" class="pc_index_advert_double_one" style="margin-right: 0; margin-left: 10px;">
                <img :src="cenAdvlist_2.thumb" :alt="cenAdvlist_2.name">
            </a>
        </div>
        <!--        pc_index_advert end-->

        <div class="pc_index_news pc_wrap">

            <div class="pc_index_tit">易行推荐</div>

            <div class="pc_index_news_wrap">
                <div class="pc_index_news_wrap_l" style="display: none;">
                    <div class="pc_index_new_wrap_l_banner">
                        <el-carousel :interval="5000" arrow="always" indicator-position='none'>
                            <el-carousel-item
                                    v-for="item in newsCars"
                                    :key="item.id"
                                    @click.native="linkToLocalNew('info',item.id)"
                            >
                                <img :src="item.thumb" :alt="item.name" />
                                <div class="pc_index_new_wrap_l_banner_name">
                                    <span class="single_line">{{item.name}}</span>
                                </div>
                                <div class="pc_index_new_wrap_l_banner_des">
                                    <span class="single_line">{{item.desc}}</span>
                                </div>

                            </el-carousel-item>
                        </el-carousel>
                    </div>

                </div>
<!--                pc_index_news_wrap_l end-->

                <div class="pc_index_news_wrap_r">
                    <el-tabs v-model="newsActiveName" @tab-click="handleClick">
                        <el-tab-pane :name="item.id" :label="item.name" v-for="(item,index) in tabsNames" :key="index">
                            <ul class="pc_index_news_wrap_r_ul">
                                <router-link tag="li" :to="{name: 'info', query:{id:item.id}}" v-for="item in newsList" :key="item.id" class="pc_index_news_wrap_r_ul_li">
                                        <div class="pc_index_news_wrap_r_ul_img">
                                            <img :src="item.thumb" :alt="item.name">
                                        </div>
                                        <div class="pc_index_news_wrap_r_ul_info">
                                            <div class="pc_index_news_wrap_r_ul_info_tit single_line">{{item.name}}</div>
                                            <div class="pc_index_news_wrap_r_ul_info_cont double_line" style="display: none;">{{item.desc}}</div>
                                        </div>
                                </router-link>
                            </ul>
                        </el-tab-pane>
                    </el-tabs>
                </div>

                <div class="pc_index_news_wrap_l">
                    <div class="pc_index_news_wrap_l_one">
                        <img :src="info_news_t.thumb" :alt="info_news_t.name">
                        <p class="single_line">{{info_news_t.name}}</p>
                        <el-button type="primary" @click="linkTo(info_news_t.link)">免费领票</el-button>
                    </div>

                    <div class="pc_index_news_wrap_l_two" @click="linkTo(info_news_b.link)">
                        <img :src="info_news_b.thumb" :alt="info_news_b.name">
                    </div>

                </div>

            </div>

            <div class="pc_index_more" style="display: none;">
                <router-link :to="{name: 'news'}" class="pc_index_more_link">更多热门行业资讯</router-link> <i class="el-icon-arrow-right"></i><i class="el-icon-arrow-right"></i><i class="el-icon-arrow-right"></i>
            </div>

        </div>
<!--        pc_index_news end-->
        <div class="pc_index_advert pc_wrap">
            <a :href="cenAdvlist_1.link?cenAdvlist_1.link:'javascript:void(0)'"
               :target="cenAdvlist_1.link?'_blank':'_self'">
                <img :src="cenAdvlist_1.thumb" :alt="cenAdvlist_1.name">
            </a>
        </div>
        <!--        pc_index_advert end-->

        <div class="pc_index_sales pc_wrap">
            <div class="pc_index_tit">{{current_month}}月销量</div>
            <div class="pc_index_sales_wrap">
                <div class="pc_index_sales_wrap_child pc_index_sales_wrap_l">
                    <div class="pc_index_sales_wrap_tit">轿车销量榜</div>
                    <ul class="pc_index_sales_wrap_list">
                        <li v-for="(item,index) in rank_data_one" :key="index">
                            <div class="pc_index_sales_wrap_list_l">
                                <div class="pc_index_sales_wrap_list_icon">
                                    <img src="../../../assets/images/pc/index/icon_1.png" alt="" v-if="index == 0">
                                    <img src="../../../assets/images/pc/index/icon_2.png" alt="" v-else-if="index == 1">
                                    <img src="../../../assets/images/pc/index/icon_3.png" alt="" v-else-if="index == 2">
                                    <span v-else>{{index + 1}}</span>
                                </div>
                                <div class="pc_index_sales_wrap_list_img">
                                    <img :src="item.url" :alt="item.name">
                                </div>
                                <div class="pc_index_sales_wrap_list_info">
                                    <div class="pc_index_sales_wrap_list_info_name">{{item.name}}</div>
                                    <div class="pc_index_sales_wrap_list_info_price">{{item.price}}</div>
                                </div>
                            </div>
                            <div class="pc_index_sales_wrap_list_num">
                                {{item.number}}
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="pc_index_sales_wrap_child pc_index_sales_wrap_c">
                    <div class="pc_index_sales_wrap_tit" style="background-color: #4cd4c0;">SUV销量榜</div>
                    <ul class="pc_index_sales_wrap_list" style="border: 1px solid #4cd4c0">
                        <li v-for="(item,index) in rank_data_two" :key="index">
                            <div class="pc_index_sales_wrap_list_l">
                                <div class="pc_index_sales_wrap_list_icon">
                                    <img src="../../../assets/images/pc/index/icon_1.png" alt="" v-if="index == 0">
                                    <img src="../../../assets/images/pc/index/icon_2.png" alt="" v-else-if="index == 1">
                                    <img src="../../../assets/images/pc/index/icon_3.png" alt="" v-else-if="index == 2">
                                    <span v-else>{{index + 1}}</span>
                                </div>
                                <div class="pc_index_sales_wrap_list_img">
                                    <img :src="item.url" :alt="item.name">
                                </div>
                                <div class="pc_index_sales_wrap_list_info">
                                    <div class="pc_index_sales_wrap_list_info_name">{{item.name}}</div>
                                    <div class="pc_index_sales_wrap_list_info_price">{{item.price}}</div>
                                </div>
                            </div>
                            <div class="pc_index_sales_wrap_list_num">
                                {{item.number}}
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="pc_index_sales_wrap_child pc_index_sales_wrap_r">
                    <div class="pc_index_sales_wrap_tit" style="background-color: #4499da;">新能源销量榜</div>
                    <ul class="pc_index_sales_wrap_list" style="border: 1px solid #4499da">
                        <li v-for="(item,index) in rank_data_third" :key="index">
                            <div class="pc_index_sales_wrap_list_l">
                                <div class="pc_index_sales_wrap_list_icon">
                                    <img src="../../../assets/images/pc/index/icon_1.png" alt="" v-if="index == 0">
                                    <img src="../../../assets/images/pc/index/icon_2.png" alt="" v-else-if="index == 1">
                                    <img src="../../../assets/images/pc/index/icon_3.png" alt="" v-else-if="index == 2">
                                    <span v-else>{{index + 1}}</span>
                                </div>
                                <div class="pc_index_sales_wrap_list_img">
                                    <img :src="item.url" :alt="item.name">
                                </div>
                                <div class="pc_index_sales_wrap_list_info">
                                    <div class="pc_index_sales_wrap_list_info_name">{{item.name}}</div>
                                    <div class="pc_index_sales_wrap_list_info_price">{{item.price}}</div>
                                </div>
                            </div>
                            <div class="pc_index_sales_wrap_list_num">
                                {{item.number}}
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </div>

        <div class="pc_index_operate">
<!--            <div class="pc_index_tit pc_wrap">-->
<!--                线上代运营-->
<!--            </div>-->
            <div class="pc_index_operate_wrap">
                <div class="pc_index_operate_wrap_cont pc_wrap">
                    <div class="pc_index_operate_wrap_cont_l" id="operate_animate_1">
                        <div class="pc_index_operate_wrap_cont_l_letter">
                            ONLINE
                        </div>
                        <div class="pc_index_operate_wrap_cont_l_tit">
                            线上推广城市网络覆盖
                        </div>
                        <div class="pc_index_operate_wrap_cont_l_info">
                            石家庄  北京  太原  广州  唐山  衡水  沧州  承德  保定  秦皇岛  邢台  阳泉  晋中  长治  运城  包头  绵阳
                            广元  重庆  内江  南充  赤峰  呼伦贝尔  广安  达州  巴中  资阳  丽江  玉溪  昆明  文山  大理  拉萨  兰州
                            银川  西宁  德阳  大连  营口  吉林  莆田  温州  衢州  赣州  宜春  青岛  泰安  郑州  十堰  荆门  荆州
                            黄冈  随州  仙桃  东莞  佛山  湛江  梅州  贵港……
                        </div>
                    </div>
                    <div class="pc_index_operate_wrap_cont_r" id="operate_animate_2">
                        <img src="../../../assets/images/pc/index/pc_index_operate_r_img.jpg" alt="">
                    </div>
                    <div class="pc_index_operate_wrap_cont_dialog" id="operate_animate_3">
                        <div class="pc_index_operate_wrap_cont_l_dialog_t">
                            从2010年涉足汽车行业，到2017年创办易行汽车开始经营车展领域，
                            在近10年的发展过程中，我们不仅成为了自办车展的区域优秀车展主办方，
                            同时服务全国车展主办方伙伴……
                        </div>
                        <el-button type="primary" class="pc_index_operate_wrap_cont_l_dialog_b" @click="linkToLocal('operate')">
                            了解易行线上推广网络</el-button>
                    </div>
                </div>

            </div>
        </div>
<!--        pc_index_operate end-->

        <div class="pc_index_contact">
<!--            <div class="pc_index_tit pc_wrap">-->
<!--                联系我们-->
<!--            </div>-->
            <div class="pc_index_contant_img pc_wrap" id="contact_animate_1">
                <img src="../../../assets/images/pc/contact/banner.jpg" alt="">
            </div>
            <div class="pc_index_contant_img pc_wrap" id="contact_animate_2">
                <img src="../../../assets/images/pc/contact/contact_1.jpg" alt="">
            </div>
            <div class="pc_index_contant_img pc_wrap" id="contact_animate_3">
                <img src="../../../assets/images/pc/contact/contact_2.jpg" alt="">
            </div>
            <div class="pc_index_contant_img pc_wrap">
                <img src="../../../assets/images/pc/contact/contact_3.jpg" alt="">
            </div>

        </div>

<!--        pc_index_contact end-->

        <pc_bottom></pc_bottom>

<!--        //飘窗-->
        <div class="pc_index_window_adv_l" v-if="is_show_window_l">
            <a :href="windowslist_l.link?windowslist_l.link:'javascript:void(0)'"
               :target="windowslist_l.link?'_blank':'_self'">
                <img :src="windowslist_l.thumb" :alt="windowslist_l.name">
            </a>
            <div class="el-icon-circle-close pc_index_window_adv_l_close" @click="close_img('l')"></div>
        </div>

        <div class="pc_index_window_adv_r" v-if="is_show_window_r">
            <a :href="windowslist_r.link?windowslist_r.link:'javascript:void(0)'"
               :target="windowslist_r.link?'_blank':'_self'">
                <img :src="windowslist_r.thumb" :alt="windowslist_r.name">
            </a>
            <div class="el-icon-circle-close pc_index_window_adv_r_close" @click="close_img('r')"></div>
        </div>

    </div>
</template>

<script>
    import $ from 'jquery';
    import new_top from "../components/new_top";
    import cars_data from '../cars/cars';
    import CountUp from 'vue-countup-v2';
    import pc_bottom from "../components/bottom";
    import lib_common from "../../../libs/lib_common";
    import _cache_city from "../../../cache/_cache_city";
    import _time from "../../../utils/_time";

    let tabsNames = [
        {
            id: '3',
            name: '新车上市'
        },
        {
            id: '4',
            name: '行业资讯'
        },
        {
            id: '5',
            name: '车展快讯'
        },
        {
            id: '6',
            name: '用车百科'
        }
    ];
    let companylist = [
        {
            icon: require('../../../assets/images/pc/index/company_icon_1.png'),
            num: 80,
            unit: '+',
            info: '合作城市'
        },
        {
            icon: require('../../../assets/images/pc/index/company_icon_2.png'),
            num: 300,
            unit: '+',
            info: '举办展会'
        },
        {
            icon: require('../../../assets/images/pc/index/company_icon_3.png'),
            num: 3500,
            unit: '万',
            info: '广告年投入近'
        },
        {
            icon: require('../../../assets/images/pc/index/company_icon_4.png'),
            num: 6,
            unit: '年',
            info: '专注汽车行业'
        }
    ];
    //2022-08-10
    let hot_cars = [
        {
            id: 1,
            name: '雅阁',
            img: 'https://www.yixingauto.com/mini/uploads/thumb/1636963599_682.png',
            price: '14.98-23.98万'
        },
        {
            id: 2,
            name: '奥迪A6L',
            img: 'https://www.yixingauto.com/mini/uploads/thumb/1636963601_9656.png',
            price: '34.85-65.68万'
        },
        {
            id: 3,
            name: '迈腾',
            img: 'https://www.yixingauto.com/mini/uploads/thumb/1636963602_2175.png',
            price: '14.19-26.49万'
        },
        {
            id: 4,
            name: '朗逸',
            img: 'https://www.yixingauto.com/mini/uploads/thumb/1636963600_0594.png',
            price: '7.2-14.49万'
        },
        {
            id: 5,
            name: '速腾',
            img: 'https://www.yixingauto.com/mini/uploads/thumb/1636963600_7648.png',
            price: '10.55-15.19万'
        },
    ];
    let video_list = [
        {
            id: 1,
            url: require('../../../assets/images/pc/video/video_1.mp4')
        },
        {
            id: 2,
            url: require('../../../assets/images/pc/video/video_2.mp4')
        },
        {
            id: 3,
            url: require('../../../assets/images/pc/video/video_3.mp4')
        },
        // {
        //     id: 4,
        //     url: require('../../../assets/images/pc/video/video_4.mp4')
        // }
    ];
    export default {
        name: "index",
        components:{
            new_top,
            cars_data,
            pc_bottom,
            CountUp,
        },
        data(){
            return{
                //城市
                city_id: '',
                //图片轮播
                banners: [],
                //车展列表
                autolist: [],
                //资讯广告位
                advlist:[],
                //数字效果
                options: {
                    useEasing: true,
                    useGrouping: true,
                    separator: ',',
                    decimal: '.',
                    prefix: '',
                    suffix: '',
                },
                delay: 1000,
                //通栏广告位
                cenAdvlist: [],
                cenAdvlist_8: {},
                cenAdvlist_7: {},
                cenAdvlist_6: {},
                cenAdvlist_5: {},
                cenAdvlist_4: {},
                cenAdvlist_3: {},
                cenAdvlist_2: {},
                cenAdvlist_1: {},
                //tabs
                tabsNames: tabsNames,
                newsActiveName: '3',
                //news
                newsCars: [],
                newsList: [],
                newsTab: '3',

                //数字
                companylist:companylist,
                //
                nowtime: '',
                //video
                videoOptions: {
                    controls:true,
                },
                player: null,
                playTime:'',
                seekTime:'',
                current:'',
                //
                hot_cars: hot_cars,
                cbh_news_list: [],
                video_list: video_list,
                //飘窗
                windowslist_l: {},
                windowslist_r: {},
                is_show_window_r: true,
                is_show_window_l: true,
                //
                rank_data_one: [],
                rank_data_two: [],
                rank_data_third: [],
                //资讯广告
                info_news_t: {},
                info_news_b: {},

                //2022-11-01 -- 销量标题
                current_month: '',

            }
        },
        methods:{
            timenow(){
                this.nowtime = _time._currentUtc();
                return
            },
            //跳转
            linkToLocal(name){
                this.$router.push({name: name})
            },
            linkToLocalNew(name,id){
                this.$router.push({name: name,query:{id: id}})
            },
            linkTo(link){
                window.open(link);
            },


            banner_list() {
                let that = this;
                lib_common.advert("1", "5", this.city_id, function (res) {
                    that.banners = res.list;
                });
            }, //图片轮播

            auto_list(){
                let that = this;
                lib_common.advert("5","8", this.city_id, function (res) {
                    that.autolist = res.list;
                });
            },//展会列表
            //资讯广告

            adv_list() {
                let that = this;
                lib_common.advert("2", "2", this.city_id, function (res) {
                    that.advlist = res.list;
                    that.info_news_t = res.list[0];
                    that.info_news_b = res.list[1];
                });
            },
            //长条广告
            cen_adv_list(){
                let that = this;
                lib_common.advert("4", "9", this.city_id, function (res) {
                    that.cenAdvlist = res.list;
                    that.cenAdvlist_8 = res.list[8];
                    that.cenAdvlist_7 = res.list[7];
                    that.cenAdvlist_6 = res.list[6];
                    that.cenAdvlist_5 = res.list[5];
                    that.cenAdvlist_4 = res.list[4];
                    that.cenAdvlist_3 = res.list[3];
                    that.cenAdvlist_2 = res.list[2];
                    that.cenAdvlist_1 = res.list[1];
                });
            },
            //飘窗广告
            windows_list() {
                let that = this;
                lib_common.advert("10", "2", this.city_id, function (res) {
                    that.windowslist_l = res.list[1];
                    that.windowslist_r = res.list[0];
                });
            },

            //tabs
            handleClick(tab){
              this.newsTab = tab.name;
              this.news_list();
            },
            //新闻
            news_cars(){
                let that = this;
                lib_common.news("3", "1", "6", function (count,list) {
                    that.newsCars = list;
                });
            },

            news_list(){
                let that = this;
                lib_common.news(this.newsTab, "1", "6", function (count,list) {
                    that.newsList = list;
                });
            },

            //
            timedata(time){
                return _time._to_line_day(time*1000);
            },

            //动画
            handleScroll: function(){
                let scrollHeight = $(window).scrollTop(); // 滚动条的滚动行程
                if(scrollHeight > 2000){
                    $("#operate_animate_1").addClass('animate__animated animate__fadeInLeftBig');
                    $("#operate_animate_2").addClass('animate__animated animate__fadeInRightBig');
                    $("#operate_animate_3").addClass('animate__animated animate__fadeInUpBig');
                }
                if(scrollHeight > 2400){
                    $("#contact_animate_1").addClass('animate__animated animate__fadeInLeftBig');
                }
                if(scrollHeight > 2800){
                    $("#contact_animate_2").addClass('animate__animated animate__bounceIn');
                }
                if(scrollHeight > 3400){
                    $("#contact_animate_3").addClass('animate__animated animate__flipInY');
                }
            },

            //cbh
            cbh_news(){
                let that = this;
                lib_common.news("1", "1", "4", function (count,list) {
                    that.cbh_news_list = list;
                });
            },
            //close
            close_img(val){
                if(val == 'r'){
                    this.is_show_window_r = false;
                    return;
                }else if(val == 'l'){
                    this.is_show_window_l = false;
                    return;
                }
            },

            //销量榜
            rank_list(){
                let that = this;
                lib_common.rank('1' , function (res) {
                    that.rank_data_one = res;
                });
            },
            rank_list_two(){
                let that = this;
                lib_common.rank('2' , function (res) {
                    that.rank_data_two = res;
                });
            },
            rank_list_third(){
                let that = this;
                lib_common.rank('3' , function (res) {
                    that.rank_data_third = res;
                });
            },

            //2022-11-01 -- 销量标题
            rank_title(){
                let data = new Date();
                let month = data.getMonth() + 1;
                let day = data.getDate();
                if(day >= 10) {
                    if(month == 1){
                        return this.current_month = 12;
                    }else{
                        return this.current_month = month - 1;
                    }
                }else{
                    return this.current_month = month;
                }
            }

        },
        mounted(){
            window.addEventListener('scroll', this.handleScroll, true);
        },
        created(){
            this.city_id = _cache_city.get();
            this.banner_list();
            this.auto_list();
            this.adv_list();
            this.cen_adv_list();
            this.news_cars();
            this.news_list();
            this.timenow();
            this.cbh_news();
            this.rank_list();
            this.rank_list_two();
            this.rank_list_third();
            this.windows_list();
            this.rank_title()
        }
    }
</script>

<style lang="less" scoped>
@import "../../../assets/style/font.css";
@import "../../../assets/style/pc/index/index.less";
</style>
<style lang="less">
    /*重写搜索框*/
.pc_index_search{
    .el-input-group{
        input{
            border: none !important;
        }
        i{
            color: #4775ff;
            font-weight: bold;
            font-size: 14pt;
        }
        .el-input-group__append{
            background: #4775ff;
            color: #fff;
            border: none;
        }
    }
}
    /*重写幻灯片*/
.pc_banner_img_l{
        .el-carousel__container {
            height: 419px !important;
        }

        img {
            width: 100%;
            height: 100%;
            position: relative;
        }

        span {
            position: absolute;
            bottom: 5px;
            right: 10px;
            color: #fff;
            font-size: 12px;
            background-color: rgba(243, 243, 243, 0.5);
            padding-left: 6px;
            padding-right: 6px;
            padding-bottom: 3px;
            padding-top: 3px;
            border-radius: 3px;
        }
    }
    /*重写幻灯片-2*/
.pc_index_new_wrap_l_banner{
        .el-carousel__container{
            height: 478px !important;
            position: relative;
        }
    }
    /*重写tabs*/
.pc_index_news_wrap_r{
    .el-tabs__header{
            height: 70px !important;
            line-height: 70px !important;
            .el-tabs__nav-wrap:after{
                background-color: rgba(0,0,0,0);
            }
            .el-tabs__item{
                height: 45px !important;
                line-height: 45px !important;
                font-size: 13pt !important;
                background-color: #f7f7f7;
                padding: 0 13%;
                margin: 0 3px;
                box-sizing: border-box;
            }
            .el-tabs__item:nth-child(2){
                padding-left: 13%;
            }
            .el-tabs__item:last-child{
                padding-right: 13%;
            }
            .el-tabs__item.is-active{
                position: relative;
                background-color: #409EFF;
                color: #fff;
            }
            .el-tabs__item.is-active:after{
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid #409EFF;
                right: 42%;
                bottom: -14px;
                transform: rotate(180deg);
            }
            .el-tabs__active-bar{
                display: none;
            }
        }
    .el-tabs__content{
        margin-top: 30px;
    }
}

</style>
