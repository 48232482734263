<template>
    <div class="wrapper pc_about">
        <pc_top></pc_top>
<!--        pc_top end-->
        <div class="pc_about_banner">
            <img src="../../../assets/images/pc/about/banner.jpg" alt="">
        </div>
<!--        pc_about_banner end-->
        <div class="pc_about_yx pc_wrap">
            <div class="pc_about_yx_top">
                <div class="pc_about_yx_top_l">
                    <div class="pc_about_yx_top_l_words">关于易行汽车</div>
                    <div class="pc_about_yx_top_l_letter">ABOUT US</div>
                </div>
                <div class="pc_about_yx_top_r">
                    01
                </div>
            </div>
            <div class="pc_about_yx_btm">
                <div class="pc_about_yx_btm_l">
                    <img src="../../../assets/images/pc/about/about_yx.jpg" alt="">
                </div>
                <div class="pc_about_yx_btm_r">
                    <div class="pc_about_yx_btm_r_tit">车生活 易在途 行其远</div>
                    <div class="pc_about_yx_btm_r_txt">{{aboutInfo.head}}
                    <p>{{aboutInfo.decs}}</p></div>
                </div>
            </div>
        </div>
<!--        pc_about_yx end-->
        <div class="pc_about_service">
            <div class="pc_wrap">
                <div class="pc_about_service_l">
                    <div class="pc_about_service_l_order">02</div>
                    <div class="pc_about_service_l_letter">OUR SERVIC</div>
                    <div class="pc_about_service_l_words">企业服务</div>
                </div>
<!--                <div class="pc_about_service_c">-->
<!--                    <img src="../../../assets/images/pc/about/service.jpg" alt="">-->
<!--                </div>-->
                <div class="pc_about_service_r">
                    <div class="pc_about_service_r_list"
                         v-for="(item,index) in servicList"
                         :class="'pc_about_service_r_list_bg_' + item.id"
                         @mouseover="mouseOver(item.id)"
                         @mouseleave="mouseLeave(item.id)"
                         :key="index">
                        <div class="pc_about_service_r_list_tit">
                            <div class="pc_about_service_r_list_tit_icon">
                                <img :src="item.icon" alt="">
                            </div>
                            <div class="pc_about_service_r_list_tit_name double_line">{{item.name}}</div>
                        </div>
                        <div class="pc_about_service_r_list_txt" :class="isToggle == item.id? 'toggle': ''">
                            {{item.decs}}
                        </div>
                    </div>

                </div>
            </div>
        </div>
<!--        pc_about_service end-->
        <div class="pc_about_frame pc_wrap">
            <div class="pc_about_frame_tit">
                <div class="pc_about_frame_tit_l">
                    <div class="pc_about_frame_tit_l_words">核心价值</div>
                    <div class="pc_about_frame_tit_l_letter">COREVALUE</div>
                </div>
                <div class="pc_about_frame_tit_r">03</div>
            </div>

            <div class="pc_about_join_list">
                <div class="pc_about_join_list_wrap">
                    <div class="pc_about_join_list_wrap_img"><img src="../../../assets/images/pc/about/about_join_1.png" alt=""></div>
                    <div class="pc_about_join_list_wrap_name">企业愿景</div>
                    <div class="pc_about_join_list_wrap_cont">做一个有价值的整合营销平台</div>
                </div>
                <div class="pc_about_join_list_wrap">
                    <div class="pc_about_join_list_wrap_img"><img src="../../../assets/images/pc/about/about_join_2.png" alt=""></div>
                    <div class="pc_about_join_list_wrap_name">企业使命</div>
                    <div class="pc_about_join_list_wrap_cont">致力成为拓客服务领域最被信任的引领者</div>
                </div>
                <div class="pc_about_join_list_wrap">
                    <div class="pc_about_join_list_wrap_img"><img src="../../../assets/images/pc/about/about_join_3.png" alt=""></div>
                    <div class="pc_about_join_list_wrap_name">核心价值观</div>
                    <div class="pc_about_join_list_wrap_cont">便捷 高效 充分发挥互联网优势</div>
                </div>
            </div>

            <div class="pc_about_frame_list" style="display: none">
                <div class="pc_about_frame_list_card">
                    <div class="pc_about_frame_list_card_img">
                        <img src="../../../assets/images/pc/about/person.jpg" alt="付朝伟">
                    </div>
                    <div class="pc_about_frame_list_card_info">
                        <div class="pc_about_frame_list_card_info_name">付朝伟</div>
                        <p>公司职位：总经理</p>
                        <p>从业经验：13年</p>
                        <div class="pc_about_frame_list_card_info_cont">
                            易行汽车创始人，深耕汽车领域十几年，在汽车展会、汽车销售、汽车后市场等方面有丰富的工作经验，多次受邀参与协助地区汽车协会、商务局等展会活动落地。
                        </div>
                    </div>
                </div>
                <div class="pc_about_frame_list_card">
                    <div class="pc_about_frame_list_card_img">
                        <img src="../../../assets/images/pc/about/person.jpg" alt="王新培">
                    </div>
                    <div class="pc_about_frame_list_card_info">
                        <div class="pc_about_frame_list_card_info_name">王新培</div>
                        <p>公司职位：运营总监</p>
                        <p>从业经验：10年</p>
                        <div class="pc_about_frame_list_card_info_cont">
                            易行汽车联合创始人，主管推广运营业务，统筹全国超80城市的广告推广业务，洞悉网络推广热门变化，调整推广活动的重心转移。
                        </div>
                    </div>
                </div>
                <div class="pc_about_frame_list_card">
                    <div class="pc_about_frame_list_card_img">
                        <img src="../../../assets/images/pc/about/person.jpg" alt="孙博">
                    </div>
                    <div class="pc_about_frame_list_card_info">
                        <div class="pc_about_frame_list_card_info_name">孙博</div>
                        <p>公司职位：销售主管</p>
                        <p>从业经验：10年</p>
                        <div class="pc_about_frame_list_card_info_cont">
                            易行汽车销售部门主管，负责车展招商、二手车、售后以及新项目开拓等业务，为易行注入更多活力，成功推动果然好、养车客两大项目的搭建完成。
                        </div>
                    </div>
                </div>
            </div>

            <div class="pc_about_frame_staff" style="display: none">
                <div class="pc_about_frame_staff_card">
                    <div class="pc_about_frame_staff_card_img">
                        <img src="../../../assets/images/pc/about/person_wb.jpg" alt="魏  斌">
                    </div>
                    <div class="pc_about_frame_staff_card_info">
                        <div class="pc_about_frame_staff_card_info_name">魏  斌</div>
                        <div class="pc_about_frame_staff_card_info_post">公司职位：销售经理</div>
                    </div>
                </div>
                <div class="pc_about_frame_staff_card">
                    <div class="pc_about_frame_staff_card_img">
                        <img src="../../../assets/images/pc/about/person_zlp.jpg" alt="赵龙坡">
                    </div>
                    <div class="pc_about_frame_staff_card_info">
                        <div class="pc_about_frame_staff_card_info_name">赵龙坡</div>
                        <div class="pc_about_frame_staff_card_info_post">公司职位：招商经理</div>
                    </div>
                </div>
                <div class="pc_about_frame_staff_card">
                    <div class="pc_about_frame_staff_card_img">
                        <img src="../../../assets/images/pc/about/portrait.jpg" alt="南向丽">
                    </div>
                    <div class="pc_about_frame_staff_card_info">
                        <div class="pc_about_frame_staff_card_info_name">南向丽</div>
                        <div class="pc_about_frame_staff_card_info_post">公司职位：客服主管</div>
                    </div>
                </div>
                <div class="pc_about_frame_staff_card">
                    <div class="pc_about_frame_staff_card_img">
                        <img src="../../../assets/images/pc/about/person_hxm.jpg" alt="郝欣淼">
                    </div>
                    <div class="pc_about_frame_staff_card_info">
                        <div class="pc_about_frame_staff_card_info_name">郝欣淼</div>
                        <div class="pc_about_frame_staff_card_info_post">公司职位：人事经理</div>
                    </div>
                </div>

            </div>

        </div>
<!--        pc_about_frame end-->
        <div class="pc_about_line"></div>
<!--        pc_about_line end-->
        <div class="pc_about_join pc_wrap" style="display: none;">
            <div class="pc_about_join_tit">
                <div class="pc_about_join_tit_words">加入我们</div>
                <div class="pc_about_join_tit_letter">JOIN US</div>
                <div class="pc_about_join_tit_cont">人才集结莫埋没金子闪闪等你来</div>
                <div class="pc_about_join_tit_img"><img src="../../../assets/images/pc/join/join_icon.png" alt=""></div>
            </div>
            <div class="pc_about_join_list">
                <div class="pc_about_join_list_wrap">
                    <div class="pc_about_join_list_wrap_img"><img src="../../../assets/images/pc/join/join_1.png" alt=""></div>
                    <div class="pc_about_join_list_wrap_name">企业愿景</div>
                    <div class="pc_about_join_list_wrap_cont">做一个有价值的整合营销平台</div>
                </div>
                <div class="pc_about_join_list_wrap">
                    <div class="pc_about_join_list_wrap_img"><img src="../../../assets/images/pc/join/join_2.png" alt=""></div>
                    <div class="pc_about_join_list_wrap_name">企业使命</div>
                    <div class="pc_about_join_list_wrap_cont">把实惠传递客户  为伙伴创造价值</div>
                </div>
                <div class="pc_about_join_list_wrap">
                    <div class="pc_about_join_list_wrap_img"><img src="../../../assets/images/pc/join/join_3.png" alt=""></div>
                    <div class="pc_about_join_list_wrap_name">核心价值观</div>
                    <div class="pc_about_join_list_wrap_cont">不做朝九晚五的打工者
                        人人争做企业的主人</div>
                </div>
            </div>
        </div>
<!--        pc_about_join end-->
        <div class="pc_about_line_2"></div>
<!--        pc_about_line_2 end-->
        <div class="pc_about_recruit pc_wrap">
            <div class="pc_about_recruit_tit">招聘岗位</div>
            <div class="pc_about_recruit_cont">
                <el-collapse v-model="active_recruitName">
                    <el-collapse-item
                            :title="items.name"
                            :name="items.id"
                            v-for="items in recruitDate"
                            :key="items.id"
                    >
                        <div>
                            <h5>岗位职责:</h5>
                            <p v-for="(item,index) in items.duty" :key="index">{{item}}</p>
                        </div>
                        <div>
                            <h5>任职要求:</h5>
                            <p v-for="(ask,index) in items.ask" :key="index">{{ask}}</p>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
<!--        pc_about_recruit end-->
        <div class="pc_about_advert pc_wrap">
            <img src="../../../assets/images/pc/about/about_contact.jpg" alt="">
        </div>
<!--        pc_index_advert end-->
        <div class="pc_about_privacy pc_wrap">
            <div class="pc_about_privacy_tit">隐私政策</div>
            <div class="pc_about_privacy_cont">
                <el-collapse v-model="active_privacyName">
                    <el-collapse-item title="用户隐私权保护声明" name="1">
                        <div class="pc_about_privacy_cont_info">
                            用户隐私权保护声明<br>
                            1.保护用户个人信息是易行汽车的一项基本原则。易行汽车将按照本声明的规定收集、使用、储存和分享您的个人信息。<br>
                            2.个人信息的收集。<br>
                            2.1我们收集您的个人信息的最终目的是为了向您提供更好的产品、服务，优化并丰富您的用户体验，这些个人信息是能够单独或者与其他信息结合识别您的个人身份的信息，包括:<br>
                            ①姓名<br>
                            ②移动电话<br>
                            ③您在网站的表格上输入的其他信息（品牌、车型、车辆行驶证等）<br>
                            ④在您上载到网站的内容中包含的任何个人信息<br>
                            2.2以上个人信息均是您自愿提供。您有权拒绝提供，但如果您拒绝提供某些个人信息，您将可能无法使用我们提供的产品、服务，或者可能对您使用产品或服务造成一定的影响。<br>
                            2.3对于不满 18 岁的用户，须在其法定监护人已经阅读本声明并且许可的情况下，通过网站提交个人信息。<br>
                            3.3易行汽车保证在未经用户合法授权时，不对外公开或向第三方提供单个用户的注册信息及用户保存在易行汽车下属网站中的非公开内容，除非有下列情况：<br>
                            3.3.1与国家安全、国防安全有关的；<br>
                            3.3.2与公共安全、公共卫生、重大公共利益有关的；<br>
                            3.3.3与犯罪侦查、起诉、审判和判决执行等有关的；<br>
                            3.3.4出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br>
                            3.3.5所收集的个人信息是个人信息主体自行向社会公众公开的；<br>
                            3.3.6从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；<br>
                            3.3.7根据您的要求签订合同所必需的；<br>
                            3.3.8用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；<br>
                            3.3.9为合法的新闻报道所必需的；<br>
                            3.3.10学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；<br>
                            3.3.11法律法规规定的其他情形。您同意，易行汽车可以通过以下方式对个人信息进行使用和分享（包含对于个人信息的存储和处理）：<br>
                            ① 我们和关联公司使用；<br>
                            ② 我们向相关有合作关系的汽车经销商、厂商及集团分享并由其使用；<br>
                            ③ 我们及关联公司及相关有合作关系的汽车经销商、厂商为满足您的需求，可能通过您提供的信息与您联系；<br>
                            ④ 我们及关联公司及相关有合作关系的汽车经销商、厂商可能定期或不定期向您发送有关产品、服务或相关活动的信息，您同意接收上述信息。<br>
                            您同意免除上述个人信息的接收和或使用方在按照本法律声明所述情形下进行信息披露和使用而导致的或可能导致的所有索赔、责任和损失。<br>
                            4．本声明未涉及的问题请参见国家有关法律法规，当本声明与国家有关法律法规冲突时，以国家法律法规为准。<br>
                            5.本网站相关声明版权及其修改权、更新权和最终解释权均属易行汽车所有。<br>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
<!--        pc_about_privacy end-->
        <pc_bottom></pc_bottom>

    </div>

</template>

<script>
    import pc_menu from "../components/menu";
    import pc_head from "../components/head";
    import pc_top from "../components/top";
    import pc_bottom from "../components/bottom";
    let aboutInfo={
        head: '河北易之行网络科技有限公司是一家服务汽车领域的新兴互联网公司。',
        decs: '主创团队于2010年进入汽车行业，2017年正式成立河北易之行网络科技有限公司。截止目前，公司业务范围已经扩展至河北，山西，广东，内蒙，湖北，四川，青海，甘肃，江西等多省份超八十个城市……'
    };
    let servicList = [
        {
            id: 1,
            name: '易行自营车展',
            decs: '作为公司最早开展的业务之一，易行车展已经拓展至全国超六十个城市，举办活动超过300场，无论从前期筹划、宣传、现场气氛，还是在到场率、订车量等方面，都被广大4S店及品牌区域充分认可，在当地形成了良好的口碑。',
            icon: require("../../../assets/images/pc/about/service_icon_1.png"),
        },
        {
            id: 2,
            name: '易迅通广告代运营',
            decs: '依托于长期以来在线上移动互联网广告投放方面积累的大量经验，公司延伸发展了线上推广业务，包括展会活动、汽车单品、二手车、房地产、餐饮加盟、美食团购等方面，年广告投入超过3500万，被众多广告投放商认可。',
            icon: require("../../../assets/images/pc/about/service_icon_2.png"),
        },
        {
            id: 3,
            name: '果然好二手车',
            decs: '这是易行汽车在面对疫情压力，整合线上推广领域积累的大量经验，以此为基础开展的全新业务板块。2022年成立河北果然好分公司，专门处理二手车方面的业务，包括：二手车全国范围推广集客、二手车线索包、二手车区域代理商等，目前已经合作超30家车商。',
            icon: require("../../../assets/images/pc/about/service_icon_3.png"),
        },
        {
            id: 4,
            name: '养车客售后系统',
            decs: '根据社会发展现状汽车后市场的用户细分需求，我们建立了一个为车主提供一站式4S店服务的o2o汽车维保服务平台——养车客。主要解决4S店基盘客户流失、保养客单价降低、套餐覆盖率不足、客户活跃度下降等问题，有效促进老客户回厂保养。',
            icon: require("../../../assets/images/pc/about/service_icon_4.png"),
        },
        {
            id: 5,
            name: '汽车品牌特卖会',
            decs: '在展会活动的基础上，公司进一步开展了针对单个品牌经销商的新车团购活动，从活动组织、推广宣传、落地执行各方面统筹安排，在提升客户到场，推动客单成交方面表现出色，先后与多家经销商集团建立了长期良好的合作关系。',
            icon: require("../../../assets/images/pc/about/service_icon_5.png"),
        },

    ]
    export default {
        name: "about",
        components:{
            pc_menu,
            pc_head,
            pc_top,
            pc_bottom
        },
        data(){
            return{
                //简介
                aboutInfo:aboutInfo,
                active_recruitName: '1',
                recruitDate: [
                    {
                        id: '1', name: '信息流优化师',
                        duty: [
                            '1、负责信息流平台广告的投放及优化，监控效果;',
                            '2、制定计划和有效管理账户素材，对文案和素材有一定的优化能力;',
                            '3、定期对账户投放方式，以及相关问题，做出判断，及时调整账户的投放方式;',
                            '4、对竞价投放的页面，有提出优化和修改的能力;',
                        ], ask:[
                            '1、专科及以上学历，有2年以上头条系信息流投放工作经验优先;',
                            '2、对数据有高度敏感性，有一定的数据分析能力，熟悉数据报表中各个环节内容以及关系，通过数据分析，挖掘产品投放增长点，优化前端投放成本，提高转化率;',
                            '3、具备创新思维，积极主动接受新鲜事物，敢于接受工作挑战;',
                        ]
                    },
                    {
                        id: '2', name: '广告渠道销售经理',
                        duty: [
                            '1、面向全国展会行业，以互联网集客产品为载体，开发有品牌推广诉求的重点企业客户;',
                            '2、根据客户品牌推广诉求，通过研究行业用户的习惯，以行业数据为载体，为大客户提供最优的集客营销方案;',
                            '3、通过行业深挖，开拓更多渠道资源，通过研究行业特点，能够为行业内客户提供适配的品牌推广策略和方案;'
                        ], ask:[
                            '1、有互联网媒体（今日头条、快手、腾讯广告、百度等）或传统媒体广告销售经验者优先录用;',
                            '2、有汽车行业相关工作经验优先录用;',
                            '3、能接受出差;',
                            '4、服务过品牌客户并有成功案例优先考虑;',
                            '5、具备良好的学习意识，能够主动学习百度及其他媒体产品，掌握全案营销的思维;',
                            '【人才画像】',
                            '1、销售欲望强烈;',
                            '2、注重个人形象',
                            '3、属于商务型谈判高手',
                            '4、思维活跃',
                            '5、人际圈子广',
                            '【加入我们您将获得什么】',
                            '1、完善的内部培训体系，可以全面学习了解大搜、信息流及其他新媒体推广工具，并全面提升品牌营销思维;',
                            '2、领先的薪酬水平，行业内绝对领先;',
                            '3、丰富的员工激励，广阔的职业发展;',
                            '薪酬福利：无责底薪+提成+五险+每月6天公休+国家法定节假日+带薪年休假+完善的福利制度+广阔的职业发展空间;'
                        ],
                    },
                    {
                        id: '3', name: '汽车行业大客户经理',
                        duty: [
                            '1、负责汽车厂家、区域、集团的大客户开发、维护工作;',
                            '2、宣传并推广公司产品服务模式，提升品牌及产品认知度;',
                            '3、对总体销售进行规划，优化产品销售结构，完成公司的销售目标;',
                            '4、发掘并跟踪潜在客户，积累客户资源，为大客户提供互联网整合营销方案;',
                        ], ask:[
                            '1、具备目标管理能力和良好的社交沟通能力;',
                            '2、从事过汽车之家、易车、懂车帝、汽车媒体相关岗位从业经验，有汽车4S店资源者优先;',
                            '3、有梦想、有责任、有担当，挑战高薪;',
                        ]
                    },
                    {
                        id: '4', name: '汽车后市场营销辅导师',
                        duty: [
                            '1、负责汽车4S店售后流失客户保养套餐团购会驻店辅导工作;',
                            '2、协助店内制定活动套餐，针对活动进行话术、流程培训;',
                            '3、管控活动落地现场环节流程，处理现场突发状况，达成最终业绩指标;'
                        ], ask:[
                            '1、汽车或市场营销相关专业大专以上学历;',
                            '2、2年以上汽车行业市场从业经验，有活动主持和培训讲师经验（有4S店展厅经理、销售经理、市场经理、内训师经验优先考虑）;',
                            '3、有大型活动策划与执行经验，具有机敏的控制现场的能力;',
                            '4、具有优秀的沟通能力、组织协调能力以及总结能力;',
                            '5、具备良好的个人形象和素养，吃苦耐劳、抗压性强;',
                            '6、可适应短期出差;',
                        ]
                    },
                    {
                        id: '5', name: '渠道招商经理',
                        duty: [
                            '1、负责所辖汽车品牌商家（4S店/经销商集团）资源的开拓与维护;',
                            '2、负责公司车展、汽车团购等业务招商与洽谈，完成销售任务;',
                            '3、负责C端客户的联系与维护;',
                            '4、负责现场活动执行与支持工作'
                        ], ask:[
                            '1、有一年以上招商、渠道及销售经验，熟悉车展、汽车行业优先;',
                            '2、具备较强的沟通表达能力、应变能力及解决问题能力;',
                            '3、性格开朗、善于交谈、积极主动、乐于学习;',
                            '4、有较强的成就欲望及抗压能力;'
                        ]
                    },
                    {
                        id: '6', name: '电销经理',
                        duty: [
                            '1、带领团队，对团队成员进行培训、辅导，达成业绩和产能目标;',
                            '2、基于业务目标为团队制订可行的策略与执行方案，推动实施，对团队业绩和产能负责;',
                            '3、确保团队可持续性建设，人员质量、架构、稳定性可支撑业务目标的达成;',
                        ], ask:[
                            '1、具备较好的营销思维，业绩导向，对指标达成有强烈欲望;',
                            '2、2年及以上的营销团队的管理经验;',
                            '3、具备营销行业专业知识，了解行业运营模式、风险、指标及管理知识;',
                            '4、具有团队意识、协同精神，良好的逻辑思维能力，深度分析解决问题的能力;'
                        ]
                    }
                ],
                active_privacyName: '',
                //
                servicList:servicList,
                //
                isToggle: 0,
            }
        },
        methods:{
            mouseOver(id){
                this.isToggle = id;
            },
            mouseLeave(){
                this.isToggle = 0;
            },
        }
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/font.css";
@import "../../../assets/style/pc/about/about";
.pc_about_service_r{
    .toggle{
        display: block !important;
    }
}

</style>
<style lang="less">
    .pc_about_recruit_cont,.pc_about_privacy_cont{
        .el-collapse {
            border-top: none;
            border-bottom: none;
            margin: 0 auto;
            width: 100%;
            box-sizing: border-box;
            .el-collapse-item {
                margin-bottom: 20px;
                background: #f5f5f5;
                padding: 0 2%;
            }
            .el-collapse-item__header {
                background-color: #f5f5f5;
                padding: 1% 0;
                font-size: 18pt;
                font-weight: bold;
            }
            .el-collapse-item__wrap {
                background-color: #f5f5f5;
                padding-top: 1%;
                border-top: 3px solid #fff;
                box-sizing: border-box;
            }
            h5 {
                font-size: 12pt;
                padding: 1% 0;
            }
            p {
                line-height: 32px;
                font-size: 12pt;
            }
        }
    }
</style>
