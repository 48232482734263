export function isPhone(rule, value, callback) {
    const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
    if (value == '' || value == undefined || value == null) {
    callback();
    } else {
        if ((!reg.test(value)) && value != '') {
            callback(new Error('请输入正确的电话号码'));
        } else {
            callback();
        }
  }
}
