<template>
    <div class="wrapper m_wrap m_news">
        <m_head></m_head>
        <!--        head end-->
        <m_menu activeClassId="4"></m_menu>

        <div class="m_news_banner">
            <img src="../../../assets/images/mobile/news/banner.jpg" alt="">
        </div>
<!--        m_news_banner end-->
        <div class="m_news_cont">
            <ul class="m_news_cont_nav">
                <li v-for="(item,index) in tab_list"
                    :key="index"

                    @click="change_tabs(item.id)"
                >
                    <img :src="item.url" :alt="item.name">
                </li>
            </ul>
            <div class="m_news_cont_wrap">
                <div class="m_news_cont_item" v-for="(item,index) in newsList" :key="index" @click="linkTo(item.id,'info')">
                    <div class="m_news_cont_item_img">
                        <img :src="item.thumb" alt="">
                    </div>
                    <div class="m_news_cont_item_info">
                        <h5 class="single_line">{{item.name}}</h5>
                        <p class="double_line">{{item.desc}}</p>
                    </div>
                </div>
            </div>

        </div>

        <pagination
                :total="data_length"
                :current="current_page"
                :size="page_size"
                @current-change="select_page"
                class="m_news_pagina"></pagination>

        <m_btm></m_btm>
    </div>
</template>

<script>
    import m_head from "../components/head";
    import m_menu from "../components/menu";
    import m_btm from "../components/bottom";
    import pagination from "../../../components/pagination"
    import lib_common from "../../../libs/lib_common";

    let tab_list = [
        {
            id: 3,
            name: '新车上市',
            icon: 'el-icon-bank-card',
            url: require('../../../assets/images/mobile/news/icon/icon_1.png')
        },
        {
            id: 4,
            name: '行业资讯',
            icon: 'el-icon-news',
            url: require('../../../assets/images/mobile/news/icon/icon_2.png')
        },
        {
            id: 5,
            name: '车展快讯',
            icon: 'el-icon-position',
            url: require('../../../assets/images/mobile/news/icon/icon_3.png')
        },
        {
            id: 6,
            name: '用车百科',
            icon: 'el-icon-thumb',
            url: require('../../../assets/images/mobile/news/icon/icon_4.png')
        }
    ]
    export default {
        name: "news",
        components:{
            m_head,
            m_menu,
            m_btm,
            pagination
        },
        data(){
            return{
                //tabs切换
                tab_list: tab_list,
                isTabnav: 3,

                newsList: [],
                //
                //数据长度
                data_length: 0,
                //当前页码
                current_page: 1,
                //每页数据条数
                page_size: 5,
            }
        },
        methods:{
            change_tabs(id){
                this.isTabnav = id;
                this.select_page();
            },


            linkTo(link,name){
                let _routers = this.$router.resolve({name: name,query:{id: link}});
                window.open(_routers.href,'_blank');
                return;
            },
            //tabs

            //回调
            reflush_list(count, list) {
                this.data_length = count;
                this.newsList = list;
            },
            select_page(page=0){
                if (page > 0) this.current_page = page;
                this.current_page = this.current_page > 0 ? this.current_page : 1;
                lib_common.news(this.isTabnav, this.current_page, this.page_size, this.reflush_list);
            },
        },
        created() {
           this.select_page(1);
        }
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/mobile/news/news";
</style>
<style lang="less">
.m_news_pagina{
    .el-pager{
        display: none;
    }
}

</style>
