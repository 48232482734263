<template>
    <div class="wrapper m_wrap m_news_info">
        <m_head></m_head>
        <!--        head end-->
        <m_menu activeClassId="4"></m_menu>

        <div class="ql-editor_wrap m_news_info_wrap">
            <div class="m_news_info_cont">
                <div class="ql-editor_wrap_tit">{{ info_list.name }}</div>
                <div class="ql-editor_wrap_cont ql-editor">
                    <p v-html="info_list.content"></p>
                </div>
            </div>
        </div>

        <m_btm></m_btm>
    </div>
</template>

<script>
    import m_head from "../components/head";
    import m_menu from "../components/menu";
    import m_btm from "../components/bottom";
    import lib_common from "../../../libs/lib_common";
    export default {
        name: "info",
        components:{
            m_head,
            m_menu,
            m_btm,
        },
        data(){
            return{
                info_list: "",
            }
        },
        methods:{
            info() {
                let that = this;
                lib_common.news_info(this.$route.query.id, function (res) {
                    that.info_list = res;
                });
            },
        },
        created() {
            this.info();
        },
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/mobile/news/info";
@import "../../../assets/style/editor";
</style>
<style lang="less">
.ql-editor_wrap_cont{
    p{
        line-height: 29px;
        font-size: 14px;
    }
    img{
        width: 100%;
    }
}
</style>
