
//微信

let _wx = {

    //取消事件
    cancel_event : 2,
    //失败事件
    error_event : 4,
    //
    ex: function(){
        if (parameters)
        {
            let options = {};
            options.title = _activity.content;
            options.desc = _data.activity_desc;
            options.link = domain + '/app/service?activity_id=' + activity_id + '&mid=' + _data.mid + '&share_mid=' + _data.mid;
            options.image = domain + _activity.cover_image;

            _wx.js_api(parameters, _wx.share, options);
        }
    },
    //调起支付
    call : function (parameters, success = null, cancel = null, error = null) {
        //console.log(parameters)
        WeixinJSBridge.invoke(
            'getBrandWCPayRequest'
            ,{
                "appId": parameters.appId,     //公众号ID，由商户传入
                "timeStamp": parameters.timeStamp,         //时间戳，自1970年以来的秒数
                "nonceStr": parameters.nonceStr, //随机串
                "package": parameters.package,
                "signType": parameters.signType,         //微信签名方式：
                "paySign": parameters.paySign //微信签名
            },
            //console.log(1),
            function (response) {
                //console.log(response)
                if(response.err_msg === "get_brand_wcpay_request:ok")
                {
                    //支付成功
                    if (typeof success === 'function')
                        success(parameters.order_id);
                }
                else if (response.err_msg === "get_brand_wcpay_request:cancel")
                {
                    //取消支付
                    if (typeof cancel === 'function')
                        cancel(parameters.order_id);
                }
                else if (response.err_msg === "get_brand_wcpay_request:fail")
                {
                    //支付失败
                    if (typeof error === 'function')
                        error(parameters.order_id);
                }

            });
    },

    js_api : function (parameters, callback, options, js_api_list = null){
        js_api_list = (js_api_list == null) ? [
            'checkJsApi',
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'onMenuShareWeibo',
            'hideMenuItems',
            'getLocation',
            'openLocation',
        ] : js_api_list;

        wx.config({
            debug: false,
            appId: parameters.appId,
            timestamp: parameters.timestamp,
            nonceStr: parameters.nonceStr,
            signature: parameters.signature,
            jsApiList: js_api_list
        });

        wx.error(function(response){
            // config信息验证失败会执行error函数，
            // 如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            console.log(response);
        });

        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
            // config信息验证后会执行ready方法，
            // 所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，
            // 所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
            wx.checkJsApi({
                jsApiList: js_api_list,
                success : function (){
                    //隐藏按钮
                    if (options.hasOwnProperty('hide'))
                        _wx.hide_menu(options.hide);

                    //成功回调
                    if (typeof callback === 'function')
                        callback(options);
                },
                fail : function () {
                    //接口调用失败时执行的回调函数
                },
                complete: function () {
                    //接口调用完成时执行的回调函数，无论成功或失败都会执行
                },
                cancel : function () {
                    //用户点击取消时的回调函数，仅部分有用户取消操作的api才会用到。
                },
                trigger: function () {
                    //监听Menu中的按钮点击时触发的方法，该方法仅支持Menu中的相关接口。
                }
            });
        });
    },

    share : function (options) {

        if (typeof options !== 'object')
            return;

        let title = options.hasOwnProperty('title') ? options.title : '标题';
        let desc = options.hasOwnProperty('desc') ? options.desc : '描述';
        let link = options.hasOwnProperty('link') ? options.link : '';
        let image = options.hasOwnProperty('image') ? options.image : '';

        if (link === '')
            return;

        _wx.share_app(title, desc, link + '&channel=1', image);
        _wx.share_space(title, link + '&channel=2', image);
        _wx.share_weibo(title, desc, link + '&channel=3', image);

    },
    share_app : function (title, desc, link, image, success = null) {
        //分享给朋友或QQ
        wx.updateAppMessageShareData({
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: image, // 分享图标
            success: (typeof success === 'function') ? success : _wx.share_success
        })
    },
    share_space : function (title, link, image, success = null) {
        //分享给朋友圈或QQ空间
        wx.updateTimelineShareData({
            title: title, // 分享标题
            link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: image, // 分享图标
            success: (typeof success === 'function') ? success : _wx.share_success
        })
    },
    share_weibo : function (title, desc, link, image, success = null) {
        //分享到腾讯微博
        wx.onMenuShareWeibo({
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: link, // 分享链接
            imgUrl: image, // 分享图标
            success: (typeof success === 'function') ? success : _wx.share_success,
        });
    },
    share_success : function () {

        //_control.layer().msg('分享成功', { time : 2 * 1000 });
    },
    share_cancel : function () {

        // _control.layer().msg('取消分享', { time : 2 * 1000 });
    },
    share_fail : function () {

        // _control.layer().msg('分享失败', { time : 2 * 1000 });
    },

    share_complete : function () {

        // _control.layer().msg('分享完成', { time : 2 * 1000 });
    },
    hide_menu : function ($menus) {
        wx.hideMenuItems({
            menuList: $menus // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
        });
    },
    init_location : function (name, address) {
        let params = {};
        params.name = name;
        params.address = address;
        _wx.get_location('gcj02', _wx.open_location, params);
    },
    get_location : function (type, callback, params) {
        wx.getLocation({
            type: type, // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: function (res) {
                let latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                let longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                // let speed = res.speed; // 速度，以米/每秒计
                // let accuracy = res.accuracy; // 位置精度
                if (typeof callback == 'function')
                {
                    callback(latitude, longitude, params);
                }
            }
        });
    },
    open_location : function (latitude, longitude, params) {

        wx.openLocation({
            latitude: latitude, // 纬度，浮点数，范围为90 ~ -90
            longitude: longitude, // 经度，浮点数，范围为180 ~ -180。
            name: params.hasOwnProperty('name') ? params.name : '', // 位置名
            address: params.hasOwnProperty('address') ? params.address : '', // 地址详情说明
            scale: params.hasOwnProperty('scale') ? params.scale : 14, // 地图缩放级别,整型值,范围从1~28。默认为最大
            infoUrl: params.hasOwnProperty('url') ? params.url : '' // 在查看位置界面底部显示的超链接,可点击跳转
        });
    },
    success : function (response, order_id) {

    },
    cancel : function (order_id) {

        let data = {};
        data.order_id = order_id;
        data.event = _wx.cancel_event;

        let params = _app._params("Action", "Report", data);
        _app._post(null, params, function (response) {
            console.log(response);
        });
    },
    error : function (order_id) {

        let data = {};
        data.order_id = order_id;
        data.event = _wx.error_event;

        let params = _app._params("Action", "Report", data);
        _app._post(null, params, function (response) {
            console.log(response);
        });
    }
};

export default _wx
