import { Message } from "element-ui";
import _request from "../common/_request";
import _base64 from "../utils/_base64";
let lib_common = {

    module: 'Common',

    city: function(callback) {
        let data = {};
        let params = _request._params(this.module, "city", data);
        _request._post_base(params, function (response) {
            if (response.code !== _request.success) {
                Message.info(response.info);
                return;
            }

            //回调数据
            if (typeof callback === 'function')
                callback(response.data)
        },0);
    },

    advert: function(tid, size, city_id, callback) {
        let data = {};
        data.tid = tid;
        data.size = size;
        data.city_id = city_id;
        let params = _request._params(this.module, "advert", data);
        _request._post_base(params, function (response) {

            if (response.code !== _request.success) {
                Message.info(response.info);
                return;
            }

            //回调数据
            if (typeof callback === 'function')
                callback(response.data)
        },0);
    },

    advert_page: function(tid, size, city, page, callback) {
        let data = {};
        data.tid = tid;
        data.size = size;
        data.city = city;
        data.page = page;

        let params = _request._params(this.module, "advert_page", data);
        _request._post_base(params, function (response) {

            if (response.code !== _request.success) {
                Message.info(response.info);
                return;
            }

            //回调数据
            if (typeof callback === 'function')
                callback(response.data)
        },0);
    },

    autoshow: function(size, city_id, callback) {
        let data = {};
        data.size = size;
        data.city_id = city_id;

        let params = _request._params(this.module, "autoshow", data);
        _request._post_base(params, function (response) {

            if (response.code !== _request.success) {
                Message.info(response.info);
                return;
            }

            //回调数据
            if (typeof callback === 'function')
                callback(response.data)
        },0);
    },

    news: function(tid, page, limit, callback) {
        let data = {};
        data.tid = tid;
        data.page = page;
        data.limit = limit;

        let params = _request._params(this.module, "news", data);
        _request._post_base(params, function (response) {
            if (response.code !== _request.success) {
                Message.info(response.info);
                return;
            }

            //回调数据
            if (typeof callback === 'function')
                callback(response.data.count,response.data.list)
        },0);
    },

    friend: function(page, limit, callback) {
        let data = {};
        data.page = page;
        data.limit = limit;

        let params = _request._params(this.module, "friend", data);
        _request._post_base(params, function (response) {

            if (response.code !== _request.success) {
                Message.info(response.info);
                return;
            }

            //回调数据
            if (typeof callback === 'function')
                callback(response.data)
        },0);
    },

    activity: function(tid, page, limit, callback) {
        let data = {};
        data.tid = tid;
        data.page = page;
        data.limit = limit;

        let params = _request._params(this.module, "activity", data);
        _request._post_base(params, function (response) {

            if (response.code !== _request.success) {
                Message.info(response.info);
                return;
            }

            //回调数据
            if (typeof callback === 'function')
                callback(response.data)
        },0);
    },

    news_info: function(id, callback) {
        let data = {};
        data.id = id;

        let params = _request._params(this.module, "news_info", data);
        _request._post_base(params, function (response) {

            if (response.code !== _request.success) {
                Message.info(response.info);
                return;
            }

            //回调数据
            if (typeof callback === 'function')
                callback(response.data)
        },0);
    },

    //
    rank: function(tid, callback) {
        let data = {};
        data.tid = tid;

        let params = _request._params(this.module, "rank", data);
        _request._post_base(params, function (response) {

            if (response.code !== _request.success) {
                Message.info(response.info);
                return;
            }

            //回调数据
            if (typeof callback === 'function')
                callback(response.data)
        },0);
    },
};

export default lib_common
