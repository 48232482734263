
import { Message } from "element-ui";
import _request from "../common/_request";

let lib_activity = {
    module: 'Common',
    info: function(id,callback) {
      let params = _request._params(this.module, "advert_info", {
        id: id,
      });
        _request._post_base(params, function (response) {

        if (response.code !== _request.success) {
          Message.info(response.info);
          return;
            }
          //回调数据
            if (typeof callback === 'function')
            callback(response.data)
      },0);
    },


};
export default lib_activity
