<template>
    <div class="wrapper pc_news">
<!--        <pc_head></pc_head>-->
<!--        &lt;!&ndash;        pc_head end&ndash;&gt;-->
<!--        <pc_menu activeClassId="4"></pc_menu>-->
<!--        &lt;!&ndash;        pc_menu end&ndash;&gt;-->
        <pc_top></pc_top>
<!--        pc_top end-->
        <div class="pc_news_banner">
            <img src="../../../assets/images/pc/news/banner.jpg" alt="">
        </div>
<!--        pc_news_banner end-->
        <div class="pc_wrap pc_news_list">
            <el-tabs v-model="newsActiveName" type="card" @tab-click="handleClick">
                <el-tab-pane :name="item.id" v-for="(item,index) in tabsNames" :key="index">
                    <div slot="label">
                        <i :class="item.icon"></i><p>{{item.name}}</p>
                    </div>
                    <ul class="pc_news_list_ul">
                        <li v-for="item in newsList" :key="item.id" class="pc_news_list_ul_li" @click="linkTo(item.id,'info')">
                            <div class="pc_news_list_ul_img">
                                <img :src="item.thumb" :alt="item.name">
                            </div>

                            <div class="pc_news_list_ul_info">
                                <div class="pc_news_list_ul_info_tit">{{item.name}}</div>
                                <div class="pc_news_list_ul_info_cont">{{item.desc}}</div>
                            </div>
                        </li>
                    </ul>
                </el-tab-pane>
                <!-- 分页 -->
                <pagination
                        :total="data_length"
                        :current="current_page"
                        :size="page_size"
                        @current-change="select_page"
                        class="pc_news_page"
                ></pagination>

            </el-tabs>
        </div>

        <pc_bottom></pc_bottom>
    </div>
</template>

<script>
    import pc_menu from "../components/menu";
    import pc_head from "../components/head";
    import pc_top from "../components/top";
    import pc_bottom from "../components/bottom";
    import pagination from "../../../components/pagination"
    import lib_common from "../../../libs/lib_common";

    let tabsNames = [
        {
            id: '3',
            name: '新车上市',
            icon: 'el-icon-bank-card',
        },
        {
            id: '4',
            name: '行业资讯',
            icon: 'el-icon-news',
        },
        {
            id: '5',
            name: '车展快讯',
            icon: 'el-icon-position',
        },
        {
            id: '6',
            name: '用车百科',
            icon: 'el-icon-thumb',
        }
    ]
    export default {
        name: "news",
        components:{
            pc_menu,
            pc_head,
            pc_top,
            pc_bottom,
            pagination
        },
        data(){
            return{
                //tabs切换
                newsActiveName: '3',
                tabsNames: tabsNames,
                newsList: [],
                newsTab: '3',
                //
                //数据长度
                data_length: 0,
                //当前页码
                current_page: 1,
                //每页数据条数
                page_size: 10,
            }
        },
        methods:{
            linkTo(link,name){
                let _routers = this.$router.resolve({name: name,query:{id: link}});
                window.open(_routers.href,'_blank');
                return;
            },
            //tabs
            handleClick(tab){
                this.newsTab = tab.name;
                this.select_page();
            },
            //回调
            reflush_list(count, list) {
                this.data_length = count;
                this.newsList = list;
            },
            select_page(page=0){
                if (page > 0) this.current_page = page;
                this.current_page = this.current_page > 0 ? this.current_page : 1;
                lib_common.news(this.newsTab, this.current_page, this.page_size, this.reflush_list);
            },
        },
        created() {
           this.select_page(1);
        }
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/pc/news/news";
</style>
<style lang="less">
    .pc_news_list{
        .el-tabs{
            .el-tabs__header{
                border-bottom: none;
                .el-tabs__nav{
                    border: none;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .el-tabs__item{
                        border-left: none;
                        height: 140px;
                        flex: 1;
                        text-align: center;
                        color: #b3bbc0;
                        i{
                            font-size: 80px;
                        }
                        p{
                            font-size: 14pt;
                        }
                    }
                    .el-tabs__item.is-active {
                        color: #409EFF;
                    }
                }
            }
            .el-tabs__content{
                margin-top: 60px;
                border-radius: 11px;
                border: 1px solid #f3f3f3;
                box-shadow: 3px -1px 13px 0px #f3f3f3;
                padding-left: 40px;
                padding-right: 40px;
                padding-bottom: 30px;
            }
        }
    }
</style>
