<template>
    <div class="wrapper pc_contact">
<!--        <pc_head></pc_head>-->
<!--        &lt;!&ndash;        pc_head end&ndash;&gt;-->
<!--        <pc_menu activeClassId="6"></pc_menu>-->
<!--        &lt;!&ndash;        pc_menu end&ndash;&gt;-->
        <pc_top></pc_top>
<!--        pc_top end-->
        <div class="pc_contact_banner">
            <img src="../../../assets/images/pc/contact/banner.jpg" alt="">
        </div>
<!--        pc_contact_banner end-->
        <div class="pc_wrap">
            <div class="pc_contact_contact">
                <img src="../../../assets/images/pc/contact/contact_1.jpg" alt="">
            </div>
            <div class="pc_contact_contact">
                <img src="../../../assets/images/pc/contact/contact_2.jpg" alt="">
            </div>
            <div class="pc_contact_contact">
                <img src="../../../assets/images/pc/contact/contact_3.jpg" alt="">
            </div>

        </div>

        <pc_bottom></pc_bottom>
    </div>
</template>

<script>
    import pc_menu from "../components/menu";
    import pc_head from "../components/head";
    import pc_top from "../components/top";
    import pc_bottom from "../components/bottom";
    export default {
        name: "contact",
        components:{
            pc_menu,
            pc_head,
            pc_top,
            pc_bottom
        },
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/pc/contact/contact";
</style>
