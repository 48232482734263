<template>
    <div class="pc_news_info wrapper">
<!--        <pc_head></pc_head>-->
<!--        &lt;!&ndash;        pc_head end&ndash;&gt;-->
<!--        <pc_menu activeClassId="4"></pc_menu>-->
<!--        &lt;!&ndash;        pc_menu end&ndash;&gt;-->
        <pc_top></pc_top>
<!--        pc_top end-->
        <div class="pc_news_info_cont pc_wrap">
            <div class="pc_news_info_cont_l">
                <div class="pc_news_info_wrap ql-editor_wrap">
                    <div class="pc_news_info_wrap_tit">{{ info_list.name }}</div>
                    <div class="pc_news_info_wrap_cont ql-editor">
                        <p v-html="info_list.content"></p>
                    </div>
                </div>
            </div>
            <div class="pc_news_info_cont_r">
                <div class="pc_news_info_cont_r_wrap" style="border: 0;" @click="linkTo(info_news_b.link)">
                    <img :src="info_news_b.thumb" :alt="info_news_b.name" style="display: block; width: 100%;">
                </div>
                <div class="pc_news_info_cont_r_wrap">
                    <div class="pc_news_info_cont_r_tit">本地热门车型</div>
                    <ul class="pc_news_info_cont_r_wrap_ul">
                        <li v-for="(item,index) in hot_cars" :key="index">
                            <div class="pc_index_recommend_r_ul_image"><img :src="item.img" :alt="item.name"></div>
                            <div class="pc_index_recommend_r_ul_price">{{item.name}}
                                <p>指导价
                                    <span>{{item.price}}</span>
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="pc_news_info_cont_r_wrap" style="padding-bottom: 10px;">
                    <div class="pc_news_info_cont_r_tit">热门资讯推荐</div>
                    <router-link tag="div" :to="{name: 'info', query:{id:item.id}}" class="pc_news_info_cont_r_wrap_list" v-for="(item,index) in cbh_news_list" :key="index">
                        <p class="pc_news_info_cont_r_wrap_new_tit single_line"><span>{{index + 1}}.</span>{{item.name}}</p>
                    </router-link>
                </div>
            </div>
        </div>

        <pc_bottom></pc_bottom>
    </div>
</template>

<script>
    import pc_menu from "../components/menu";
    import pc_head from "../components/head";
    import pc_top from "../components/top";
    import pc_bottom from "../components/bottom";
    import lib_common from "../../../libs/lib_common";
    let hot_cars = [
        {
            id: 1,
            name: '雅阁',
            img: 'https://www.yixingauto.com/mini/uploads/thumb/1636963599_682.png',
            price: '14.98-23.98万'
        },
        {
            id: 2,
            name: '奥迪A6L',
            img: 'https://www.yixingauto.com/mini/uploads/thumb/1636963601_9656.png',
            price: '34.85-65.68万'
        },
        {
            id: 3,
            name: '迈腾',
            img: 'https://www.yixingauto.com/mini/uploads/thumb/1636963602_2175.png',
            price: '14.19-26.49万'
        },
        {
            id: 4,
            name: '朗逸',
            img: 'https://www.yixingauto.com/mini/uploads/thumb/1636963600_0594.png',
            price: '7.2-14.49万'
        },
        {
            id: 5,
            name: '速腾',
            img: 'https://www.yixingauto.com/mini/uploads/thumb/1636963600_7648.png',
            price: '10.55-15.19万'
        },
    ];
    export default {
        name: "info",
        components:{
            pc_menu,
            pc_head,
            pc_top,
            pc_bottom,
        },
        data(){
            return{
                info_list: "",
                hot_cars: hot_cars,
                cbh_news_list: [],
                info_news_b: {},
            }
        },
        methods:{
            info() {
                let that = this;
                lib_common.news_info(this.$route.query.id, function (res) {
                    that.info_list = res;
                });
            },
            cbh_news(){
                let that = this;
                lib_common.news("1", "1", "11", function (count,list) {
                    that.cbh_news_list = list;
                });
            },
            adv_list() {
                let that = this;
                lib_common.advert("2", "3", this.city_id, function (res) {
                    that.info_news_b = res.list[2];
                });
            },
            linkTo(link){
                window.open(link);
            },
        },
        created() {
            this.info();
            this.cbh_news();
            this.adv_list();
        },
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/pc/news/info";
</style>
<style lang="less">
    @import "../../../assets/style/editor";
.pc_news_info_wrap_cont{
    p{
        line-height: 29px;
    }
    img{
        width: 100%;
    }
}
</style>
