<template>
    <div class="wrapper pc_auto">
<!--        <pc_head></pc_head>-->
<!--        &lt;!&ndash;        pc_head end&ndash;&gt;-->
<!--        <pc_menu activeClassId="3"></pc_menu>-->
<!--        &lt;!&ndash;        pc_menu end&ndash;&gt;-->
        <pc_top></pc_top>
<!--        pc_top end-->
        <div class="pc_auto_banner">
            <img src="../../../assets/images/pc/auto/banner.jpg" alt="">
        </div>
<!--        pc_auto_banner end-->

        <div class="pc_auto_search pc_wrap">
            <el-input
                    placeholder="请输入城市名称"
                    prefix-icon="el-icon-search"
                    v-model="search_name"
                    clearable
                    @clear="clearSearch"
            >
                <el-button slot="append" @click="getAuto()">搜索</el-button>
            </el-input>
        </div>
<!--        pc_auto_search end-->
        <div class="pc_auto_map" style="margin: 0 auto 60px;">
            <img src="../../../assets/images/pc/auto/auto_map_new.jpg" alt="" style="display: block;width: 100%;">
        </div>
<!--        pc_auto_map end-->
        <div class="pc_auto_list pc_wrap">


            <div class="pc_auto_list_wrap">
                <a
                        class="pc_auto_list_info"
                        v-for="(item, index) in autolist"
                        :target="item.link?'_blank':'_self'"
                        :key="index"
                        :href="item.link?item.link:'javascript:void(0)'"
                >
                    <div class="pc_auto_list_info_img">
                        <img :src="item.thumb" :alt="item.name">
                    </div>
                    <div class="pc_auto_list_info_name">
<!--                        <p>{{ timedata(item.stime) }}</p>-->
                        <p class="single_line pc_auto_list_info_name_tit">{{ item.name }}</p>
                    </div>
                    <div class="top_right_sub jxz" v-if="nowtime <= item.etime">进行中</div>
                    <div class="top_right_sub yjs" v-else>已结束</div>
                </a>
            </div>


<!--            <div class="pc_auto_list_more">-->
<!--                更多城市展会信息 <i class="el-icon-arrow-right"></i><i class="el-icon-arrow-right"></i><i class="el-icon-arrow-right"></i>-->
<!--            </div>-->

            <!-- 分页 -->
            <div v-if="isList" class="pc_auto_list_wrap_none">该城市暂无数据</div>

            <pagination
                    :total="data_length"
                    :current="current_page"
                    :size="page_size"
                    @current-change="select_page"
                    class="pc_news_page"
            ></pagination>

        </div>

        <pc_bottom></pc_bottom>
    </div>
</template>

<script>
    import pc_menu from "../components/menu";
    import pc_head from "../components/head";
    import pc_top from "../components/top";
    import pc_bottom from "../components/bottom";
    import pagination from "../../../components/pagination"
    import lib_common from "../../../libs/lib_common";
    import _cache_city from "../../../cache/_cache_city";
    import _time from "../../../utils/_time";
    export default {
        name: "auto",
        components:{
            pc_menu,
            pc_head,
            pc_top,
            pc_bottom,
            pagination
        },
        data(){
            return{
                //城市
                city_id: '',
                //车展列表
                autolist: [],
                //
                nowtime: '',
                //数据长度
                data_length: 0,
                //当前页码
                current_page: 1,
                //每页数据条数
                page_size: 6,

                //城市筛选
                search_name: '',

                //
                isList: false,

            }
        },

        methods:{

            timedata(time){
                return _time._to_day(time*1000);
            },
            timenow(){
                this.nowtime = _time._currentUtc();
                return
            },

            //回调
            reflush_list(data) {

                this.data_length = data.count;
                this.autolist = data.list;
            },

            select_page(page = 0){
                if (page > 0) this.current_page = page;
                this.current_page = this.current_page > 0 ? this.current_page : 1;
                lib_common.advert_page('5', this.page_size, this.search_name,  this.current_page, this.reflush_list);
            },
            //检索
            getAuto(){
                this.select_page(1);
            },
            clearSearch(){
                this.search_name = '';
                this.select_page(1)
            }

        },
        created(){
            this.city_id = _cache_city.get();
            this.search_name = this.$route.params.search_name;
            this.getAuto();
            this.timenow();

        }
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/pc/auto/auto";
</style>
<style lang="less">
.pc_auto_search{
    .el-input-group{
        width: 40%;
        .el-button{
            background-color: #4775ff;
            color: #fff;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}
</style>
