
let __tf = function(i){ return (i < 10 ? '0' : '') + i; };

//时间戳毫秒级
let __format = function (timestamp) {
  let now = (timestamp === null || timestamp === "") ? new Date : new Date(timestamp),
      y = now.getFullYear(),
      m = __tf(now.getMonth() + 1),
      d = __tf(now.getDate());
  return y + "-" + m + "-" + d + " " + now.toTimeString().substr(0, 8);
};

//时间处理工具
let _time = {
  //以某时区为基础的当前时间戳
  _currentUtc : function (toUtc = 0, bit = 10) {
    let d = new Date();
    let local = d.getTime();
    let localUtc = d.getTimezoneOffset() / 60;
    let offset = toUtc - localUtc;
    let localTime = (local - 3600000 * offset);
    return (bit === 13) ? localTime : Math.floor(localTime / 1000);
  },

  //获取某时区时间点时间格式
  _utcToDate : function (timestamp, utc) {
    timestamp = (timestamp == null) ? this._currentUtc(utc, 13) : timestamp;
    let d = new Date(timestamp);
    let f = {};
    f.Y = d.getFullYear() + '年';
    f.m = d.getMonth() + 1 + '月';
    f.d = d.getDate() + '日 ';
    f.H = d.getHours() + ':';
    f.m = d.getMinutes() + ':';
    f.s = d.getSeconds();
    return f.Y + f.m + f.d + f.H + f.m + f.s;
  },

  //获取本机时区
  _localUtc : function () {

    return new Date().getTimezoneOffset() / 60;
  },

  //当前时间戳
  _current : function (bit = 10) {

    // let divisor = (bit === 13) ? 1 : 1000;
    // return Date.parse(new Date()) / divisor;
    return (bit === 13) ? Date.now() : Math.floor(Date.now() / 1000);
  },

  //当前时间
  _now : function(){

    return __format(null);
  },

  //N天后时间
  _n_date : function (n) {

    let ndate = new Date();
    ndate.setDate(ndate.getDate() + n);

    let y, m, d;
    y = ndate.getFullYear(),
        m = __tf(ndate.getMonth() + 1),
        d = __tf(ndate.getDate());
    return y + "-" + m + "-" + d + " " + ndate.toTimeString().substr(0, 8);
  },

  //毫秒级转字符串
  _to_date : function(timestamp){

    return __format(timestamp);
  },

  //毫秒级转日期
  _to_day : function(timestamp){

    let now = (timestamp === null || timestamp === "") ? new Date : new Date(timestamp),
        y = now.getFullYear(),
        m = __tf(now.getMonth() + 1),
        d = __tf(now.getDate());
    return y + "年" + m + "月" + d + "日";
  },

  //横线
  _to_line_day : function(timestamp){

    let now = (timestamp === null || timestamp === "") ? new Date : new Date(timestamp),
        y = now.getFullYear(),
        m = __tf(now.getMonth() + 1),
        d = __tf(now.getDate());
    return y + "-" + m + "-" + d;
  },

  //毫秒级转日期
  _to_short_day : function(timestamp){

    let now = (timestamp === null || timestamp === "") ? new Date : new Date(timestamp),
        y = now.getFullYear(),
        m = __tf(now.getMonth() + 1),
        d = __tf(now.getDate());
    return y + "" + m + "" + d;
  },

  //字符串转时间戳毫秒级
  _to_time : function (date) {

    return Date.parse(date);
  },

  //时间转化
  _to_short : function (timestamp) {

    let now = (timestamp === null || timestamp === "") ? new Date : new Date(timestamp),
        m = __tf(now.getMonth() + 1),
        d = __tf(now.getDate());
    return m + "-" + d + " " + now.toTimeString().substr(0, 5);
  },

  //斜杠
  _to_slash : function (timestamp) {

    let now = (timestamp === null || timestamp === "") ? new Date : new Date(timestamp),
        y = now.getFullYear(),
        m = __tf(now.getMonth() + 1),
        d = __tf(now.getDate());
    return y + "/" + m + "/" + d + " " + now.toTimeString().substr(0, 5);
  },

  //比如时间戳日期是否一致
  _compare_timestamp_date : function (timestamp_1, timestamp_2) {

    let date_1 = __tf(new Date(timestamp_1).getDate());
    let date_2 = __tf(new Date(timestamp_2).getDate());
    return date_1 === date_2;
  },

  //计算时间差-剩余天数
  _remain_day : function (current_time, deadline_time) {

    let remain = deadline_time - current_time;
    if (remain < 0)
      return 0;

    if (remain < 24 * 3600)
      return this._compare_timestamp_date() ? 0 : 1;

    return Math.ceil(remain / 86400);
  },

  //转化成时间戳
  _to_timestmap : function (date) {
    date = new Date(date);
    return Math.floor(date.getTime() / 1000)
  }
};

//导出
export default _time;
