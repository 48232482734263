<template>
    <div class="wrapper pc_operate">
        <pc_top></pc_top>
<!--        pc_top end-->
        <div class="pc_operate_banner">
            <img src="../../../assets/images/pc/operate/banner.jpg" alt="">
        </div>
<!--        pc_operate_banner end-->
        <div class="pc_wrap" style="padding-bottom: 270px;">
            <div class="pc_operate_case">
                <div class="pc_operate_case_top">
                    <div class="pc_operate_case_top_l">
                        易行代运营合作案例
                    </div>
                    <div class="pc_operate_case_top_r">
                        <span v-for="(item,index) in toggleTabs"
                              :key="index"
                              :class="advId == item.id ? 'cur' : '' "
                              @click="toggle_tabs(item.id)">
                            {{item.name}}
                        </span>
                    </div>
                </div>
                <div class="pc_operate_case_btm">
                    <el-carousel :interval="4000" type="card" height="530px">
                        <el-carousel-item v-for="(item,index) in advList" :key="index" @click.native="item.link?linkTo(item.link):''">
                            <img :src="item.thumb" :alt="item.name" class="pc_operate_case_btm_img">
                            <div class="pc_operate_case_btm_name">{{item.name}}</div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
            <!--        pc_operate_case end-->
            <div class="pc_operate_pange">
                <div class="pc_operate_pange_tit">业务覆盖范围</div>
                <div class="pc_operate_pange_cont">
                    <div class="pc_operate_pange_cont_l">
                        <img src="../../../assets/images/pc/operate/pange_l.png" alt="">
                    </div>
                    <div class="pc_operate_pange_cont_r">
                        <div class="pc_operate_pange_cont_r_t">
                            华北燕赵车博会 西南汽车嘉华会 西北欢乐购车节<br>
                            东北全民购车季 华东惠购车展 华中车博会<br>
                            华南汽车博览会
                        </div>
                        <div class="pc_operate_pange_cont_r_btm">
                            <div class="pc_operate_pange_cont_r_btm_info">
                                从2010年涉足汽车行业，到2017年创办易行汽车
                                开始经营车展领域，在近10年的发展过程中，我们
                                不仅成为了自办车展的区域优秀车展主办方，同时
                                服务全国车展主办方伙伴，目前易行线上推广城市
                                网络已经覆盖80城市（石家庄、北京、太原、
                                广州、唐山、衡水、沧州、承德、保定、秦皇岛、
                                邢台、阳泉、晋中、长治、运城、包头、绵阳、
                                广元、重庆、内江、南充、赤峰、呼伦贝尔、
                                广安、达州、巴中、资阳、丽江、玉溪、昆明、
                                文山、大理、拉萨、兰州、银川、西宁、德阳、
                                大连、营口、吉林、莆田、温州、衢州、赣州、
                                宜春、青岛、泰安、郑州、十堰、荆门、荆州、
                                黄冈、随州、仙桃、东莞、佛山、湛江、梅州、
                                贵港等），未来我们也会再接再厉，为所有用户提供
                                更好的服务体验。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
<!--            pc_operate_pange end-->
            <div class="pc_operate_adv">
                <div class="pc_operate_adv_tit">广告投放渠道</div>
                <div class="pc_operate_adv_cont">
                    <img src="../../../assets/images/pc/operate/adv_img.png" alt="">
                </div>
            </div>
<!--            pc_operate_adv end-->

            <div class="pc_operate_tage">
                <div class="pc_operate_tage_tit">广告投放优势</div>
                <div class="pc_operate_tage_cont">
                    <img src="../../../assets/images/pc/operate/pc_operate_tage_img_1.jpg" alt="">
                </div>
                <div class="pc_operate_tage_cont" style="display: none;">
                    <div class="pc_operate_tage_cont_img">
                        <img src="../../../assets/images/pc/operate/tage_1.jpg" alt="">
                        <p>平台覆盖全面</p>
                    </div>
                    <div class="pc_operate_tage_cont_img">
                        <img src="../../../assets/images/pc/operate/tage_2.jpg" alt="">
                        <p>有效运营优化</p>
                    </div>
                    <div class="pc_operate_tage_cont_img">
                        <img src="../../../assets/images/pc/operate/tage_3.jpg" alt="">
                        <p>效果导向投放</p>
                    </div>
                    <div class="pc_operate_tage_cont_img">
                        <img src="../../../assets/images/pc/operate/tage_4.jpg" alt="">
                        <p>专业投放策划</p>
                    </div>
                </div>
            </div>
<!--            pc_operate_tage end-->


        </div>
        <div class="pc_operate_contact">
            <img src="../../../assets/images/pc/operate/pc_operate_contact.jpg" alt="">
        </div>
        <!--            pc_operate_contact end-->

        <pc_bottom></pc_bottom>
    </div>
</template>

<script>
    import pc_menu from "../components/menu";
    import pc_head from "../components/head";
    import pc_top from "../components/top";
    import pc_bottom from "../components/bottom";
    import lib_common from "../../../libs/lib_common";

    let toggleTabs = [
        {
            id: '6',
            name: '展会推广'
        },
        {
            id: '7',
            name: '单品推广'
        }
    ]
    export default {
        name: "operate",
        components:{
            pc_menu,
            pc_head,
            pc_top,
            pc_bottom
        },
        data(){
            return{
                advId: '6',
                advList: [],
                //
                toggleTabs: toggleTabs
            }
        },
        methods:{
            adv_list(){
                let that = this;
                lib_common.advert(this.advId, "4", this.city_id, function (res) {
                    that.advList = res.list;
                });
            },
            toggle_tabs(id){
                this.advId = id;
                this.adv_list();
            },
            linkTo(link){
                window.open(link);
            }
        },
        created() {
            this.adv_list()
        }
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/pc/operate/pc_operate";
</style>
<style lang="less">
.pc_operate_case_btm{
    .el-carousel__button{
        height: 7px;
        border-radius: 5px;
        background-color: #4775ff;
    }
}

</style>
