<template>
    <div class="wrapper m_wrap m_auto" >
        <m_head></m_head>
        <!--        head end-->
        <m_menu activeClassId="2"></m_menu>
        <!--        m_menu end-->

        <div class="m_auto_banner">
            <img src="../../../assets/images/mobile/auto/banner.png" alt="">
        </div>
<!--        m_auto_banner end-->
        <div class="m_auto_search">
            <el-input  placeholder="请输入城市" v-model="search_name" @clear="clearSearch" clearable>
                <el-button slot="append" icon="el-icon-search" @click="getAuto()"></el-button>
            </el-input>

        </div>
<!--        m_auto_search end-->
        <div class="m_auto_cont">
            <a :href="item.link?item.link:'javascript:void(0)'" :key="index" :target="item.link?'_blank':'_self'" class="m_auto_cont_list" v-for="(item,index) in autolist">
                <img :src="item.thumb" :alt="item.name">
                <p class="single_line">{{ item.name }}</p>
                <el-button class="m_auto_cont_list_btn" size="mini" round type="primary" v-if="nowtime <= item.etime">报名中</el-button>
                <el-button class="m_auto_cont_list_btn" size="mini" round type="info" disabled v-else>已结束</el-button>
            </a>
        </div>

        <pagination
                :total="data_length"
                :current="current_page"
                :size="page_size"
                @current-change="select_page"
                class="m_auto_page"
        ></pagination>

        <m_btm></m_btm>


    </div>
</template>

<script>
    import m_head from "../components/head";
    import m_menu from "../components/menu";
    import m_btm from "../components/bottom";

    import pagination from "../../../components/pagination"
    import lib_common from "../../../libs/lib_common";
    import _cache_city from "../../../cache/_cache_city";
    import _time from "../../../utils/_time";
    export default {
        name: "auto",
        components:{
            m_head,
            m_menu,
            m_btm,
            pagination
        },
        data(){
            return{
                //城市
                city_id: '',
                //车展列表
                autolist: [],
                //
                nowtime: '',
                //数据长度
                data_length: 0,
                //当前页码
                current_page: 1,
                //每页数据条数
                page_size: 10,

                //城市筛选
                search_name: '',

                //
                isList: false,


            }
        },


        methods:{

            timedata(time){
                return _time._to_day(time*1000);
            },
            timenow(){
                this.nowtime = _time._currentUtc();
                return
            },

            //回调
            reflush_list(data) {
                this.data_length = data.count;
                this.autolist = data.list;
            },


            select_page(page = 0){
                if (page > 0) this.current_page = page;
                this.current_page = this.current_page > 0 ? this.current_page : 1;
                lib_common.advert_page('5', this.page_size, this.search_name,  this.current_page, this.reflush_list);
            },
            //检索
            getAuto(){
                this.select_page(1);
            },
            clearSearch(){
                this.isList = false;
                this.search_name = '';
                this.select_page(1)
            }

        },
        created(){
            this.city_id = _cache_city.get();
            this.search_name = this.$route.params.search_name;
            this.getAuto();
            this.timenow();

        }
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/mobile/auto/auto";
</style>
<style lang="less">
.m_auto_page{
    .el-pager{
        display: none;
    }
}

</style>
