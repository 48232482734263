<template>
    <div class="pc_menu">
        <ul class="pc_menu_ul pc_wrap">
            <li v-for="(item, index) in menulist"
                :key="index"
                @click="ToPage(item.src,item.id)"
                :class="activeClassId == item.id ? 'botm_link':''"
                >
                {{item.name}}
            </li>
        </ul>
    </div>
</template>

<script>
    import _config from "../../../common/_config";
    let menulist = [
        {
            id: 1,
            name: "首页",
            src: _config.prefix + "/home",
        },
        {
            id: 2,
            name: '关于易行',
            src: _config.prefix + '/about',
        },
        {
            id: 3,
            name: '城市车展',
            src: _config.prefix + '/auto',
        },
        {
            id: 4,
            name: '车市前沿',
            src: _config.prefix + '/news',
        },
        {
            id: 5,
            name: '线上代运营',
            src: _config.prefix + '/operate',
        },
        {
            id: 6,
            name: '联系我们',
            src: _config.prefix + '/contact',
        }
    ]
    export default {
        name: "pc_menu",
        props:{
            activeClassId: {
                type: String,
                default: '1'
            }
        },
        data(){
            return{
                menulist: menulist
            }
        },
        methods:{
            ToPage(url,classId){
                this.$router.push({path: url});
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../../assets/style/pc/components/menu";
</style>
