<template>
    <div class="pc_top">
        <div class="pc_top_wrap pc_wrap">
            <div class="pc_top_l">
                <div class="pc_top_logo"><img src="../../../assets/images/logo_white.png" alt="易行汽车"></div>
                <div class="pc_top_city"
                     @click="showcity()">
                    <i class="el-icon-location-outline"></i>
                    <span>{{ city_name }}</span>
                </div>
            </div>
            <div class="pc_top_c">
                <ul class="pc_top_c_menu">
                    <li v-for="(item, index) in menulist"
                        :key="index"
                        @click="ToPage(item.src)"
                    >
                        <span>{{item.name}}</span>
                    </li>
                </ul>
                <div class="pc_top_r">
                    <el-input
                            placeholder="请输入城市名称"
                            prefix-icon="el-icon-search"
                            v-model="search_name"
                            clearable
                    >
                        <el-button slot="append" @click="getAuto()">搜索</el-button>
                    </el-input>
                </div>
            </div>

        </div>

        <!--       //城市-->
        <el-dialog class="pc_citys" :visible.sync = "citysdialog" :modal="false">
            <div class="pc_citys_wrap">
                <!-- // -->
                <template v-for="(city_list, letter) in citys">
                    <div class="pc_citys_directly" :key="letter">
                        <div class="pc_citys_dir_name1" :id="'letter_' + letter">{{ letter }}</div>
                        <div class="pc_citys_dir_list">
                            <div class="pc_citys_dir_list_ul">
                                <ul class="pc_citys_dir_list_info">
                                    <li
                                            v-for="city in city_list"
                                            :key="city.id"
                                            @click="getCity(city.id)"
                                    >
                                        {{ city.name }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </template>
                <!-- // -->
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import _config from "../../../common/_config";
    import lib_common from "../../../libs/lib_common";
    import _cache_city from "../../../cache/_cache_city";

    let menulist = [
        {
            id: 1,
            name: "首页",
            src: _config.prefix + "/home",
        },

        {
            id: 2,
            name: '城市车展',
            src: _config.prefix + '/auto',
        },
        {
            id: 3,
            name: '线上代运营',
            src: _config.prefix + '/operate',
        },
        {
            id: 4,
            name: '车市前沿',
            src: _config.prefix + '/news',
        },
        {
            id: 5,
            name: '关于易行',
            src: _config.prefix + '/about',
        },
        {
            id: 6,
            name: '联系我们',
            src: _config.prefix + '/contact',
        }
    ]
    export default {
        name: "top",
        data(){
            return{
                //城市
                citysdialog: false,
                citys: [],
                city_id: 1301,
                city_name: "石家庄",
                //菜单
                menulist: menulist,
                //搜索
                search_name: ''
            }
        },
        methods:{
            showcity() {
                this.citysdialog = !this.citysdialog;
            },
            getCity(id) {
                this.citysdialog = false;
                window.location.href =
                    window.location.protocol +
                    "//" +
                    window.location.host + '/app'+
                    _config.prefix +
                    "/home?city_id=" +
                    id;
            },
            renderCity() {
                lib_common.city(this.to_citys);
            },
            to_citys(data) {
                this.citys = data;
                for (let letter in data) {
                    for (let i in data[letter]) {
                        if (this.city_id == data[letter][i].id) {
                            this.city_name = data[letter][i].name;
                            return;
                        }
                    }
                }
            },
            getAuto(){
                this.$router.push({name: 'auto',params:{search_name: this.search_name}});
            },
            ToPage(url){
                this.$router.push({path: url});
            }
        },
        created() {
            this.city_id = _cache_city.get();
            this.renderCity();
        },
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/pc/components/top";
@import "../../../assets/style/pc/components/citys";
</style>
<style lang="less">
.pc_top{
    .pc_citys{
        .el-dialog{
            margin-top: 61px !important;
            .el-dialog__header{
                display: none;
            }
        }
    }
}

</style>
