import axios from 'axios'
import _time from '../utils/_time'
import _md5 from '../utils/_md5'
import _base64 from '../utils/_base64'
import _object from "../utils/_object";
import _layer from "./_layer";

import _config from '../common/_config'

//互斥锁
let __mutex = false;
//明钥
let __KEY = "COMMON@KEY.API";
//统一接口
let __url = "/request";
//统一域名地址
let __base_url = process.env.NODE_ENV === 'production' ? window.location.protocol + '//' + window.location.host : 'http://192.168.31.139:82';
//接口超时时间
let __timeout = 10 * 1000;
//允许最大数据长度
let __max_content_length = 4*1024*1024;

//type
let __method = {
  _get : 'GET',
  _post : 'POST',
  _put : 'PUT',
  _del : 'DELETE',
};

//content-type
let __content_type = {
  _primary : 'application/x-www-form-urlencoded',
  _file : 'multipart/form-data',//false
  _json : 'application/json;charset=utf-8',
  _xml : 'text/xml',
};

//data-type
let __data_type = {
  _json : 'json',
  _xml : 'XML',
};


//加锁私有函数
let __lock = function () {
  __mutex = true;
};

//解锁私有函数
let __unlock = function () {
  __mutex = false;
};

//JSON序列化并加密
let __encode = function (params) {
  return _base64._encode(JSON.stringify(params));
};

//解密并JSON反序列化
let __decode = function (response) {
  return JSON.parse(_base64._decode(response));
};

//默认错误函数回调
let __error = function(e, lock) {
  if (lock)
  {
    //解锁
    __unlock();
    //关闭加载
    _layer._load_close();
  }
};

//校验
let __validate = function(url, lock, success, error){

  if (lock && __mutex)
    return false;

  if (url != null && (typeof url !== 'string' || url === ''))
  {
    console.log("url is not valid:", url);
    return false;
  }

  if(typeof success !== "function" || typeof error !== "function")
  {
    console.log('success or error is not function', success, error);
    return false;
  }

  return true;
};

//ajax私有函数
let __ajax = function(url, method, params, data_ype, timeout, request_before, response_after, headers, serialize, success, error, lock){

  if (lock)
  {
    _layer._load();
    //自动落锁
    __lock();
  }

  let options = {};
  //将用于请求的服务器URL
  options.url = (url == null) ? __url : url;
  //发出请求时使用的请求方式
  options.method = method;
  //作为请求主体发送的数据
  //仅适用于请求方法“PUT”，“POST”和“PATCH”
  options.data = params;
  //用于GET，请求一起发送的URL参数
  //必须是纯对象或URLSearchParams对象
  // options.params = params;
  //将被添加到url前面，除非url是绝对的。
  options.baseURL = __base_url;
  //在请求数据发送到服务器之前对其进行更改
  //只适用于请求方法'PUT'，'POST'和'PATCH'
  if (typeof request_before === "function")
    options.transformRequest = [request_before];
  //允许在 then / catch之前对响应数据进行更改
  if (typeof response_after === "function")
    options.transformResponse = [response_after];
  //自定义header
  options.headers = headers;
  //可选的函数，负责序列化params
  if (typeof serialize === "function")
    options.paramsSerializer = serialize;
  //指定请求超时之前的毫秒数。
  //如果请求的时间超过，请求将被中止。
  options.timeout = timeout;
  //是否跨站点访问控制请求
  options.withCredentials = true; // default false
  //允许自定义处理请求，这使得测试更容易。
  //返回一个promise并提供一个有效的响应
  // options.adapter = function (config) {};
  //表示应该使用 HTTP 基本认证，并提供凭据。
  //这将设置一个`Authorization'头，覆盖任何现有的`Authorization'自定义头，使用`headers`设置。
  // options.auth = {};
  //表示服务器将响应的数据类型
  //包括 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
  options.responseType = data_ype; // default
  //要用作 xsrf 令牌的值的cookie的名称
  // options.xsrfCookieName = 'XSRF-TOKEN'; // default
  //携带xsrf令牌值的http头的名称
  // options.xsrfHeaderName = 'X-XSRF-TOKEN'; // default
  //允许处理上传的进度事件
  // options.onUploadProgress = function (progressEvent) {};
  //允许处理下载的进度事件
  // options.onDownloadProgress = function (progressEvent) {};
  //定义允许的http响应内容的最大大小
  options.maxContentLength = __max_content_length;
  //定义是否解析或拒绝给定的promise
  //HTTP响应状态码。如果`validateStatus`返回`true`（或被设置为`null` promise将被解析;否则，promise将被拒绝。
  // options.validateStatus = function (status) {
  //   return status >= 200 && status < 300; // default
  // };
  //定义在node.js中要遵循的重定向的最大数量。
  //如果设置为0，则不会遵循重定向。
  options.maxRedirects = 5; // 默认
  //用于定义在node.js中分别执行http和https请求时使用的自定义代理。
  // 允许配置类似`keepAlive`的选项，
  // 默认情况下不启用。
  // options.httpAgent = new http.Agent({ keepAlive: true });
  // options.httpsAgent = new https.Agent({ keepAlive: true });
  //定义代理服务器的主机名和端口
  // options.proxy = {};
  //指定可用于取消请求的取消令牌
  // options.cancelToken = new CancelToken(function (cancel) { });
  //执行
  axios(options).then(function (response) {

    if (lock)
    {
      //解锁
      __unlock();
      //关闭加载
      _layer._load_close();
    }

    if (response.data)
    {
      success(response.data);
    }

  }).catch(function (e) {
      error(e, lock)
  });
};



//定义类
let _request = {

  //成功标记
  success : 0,

  //初始化参数头
  _params : function (module, fn, data = null) {
    let time = _time._current();
    let sign = _md5._hex_md5(time + __KEY + module + fn);

    let params = {};
    params.time = time;
    params.sign = sign;
    params.module = module;
    params.fn = fn;
    params.data = _object._length(data) > 0 ? data : {};
    return params;
  },

  //post请求 --- 官网
  _post_base : function (params, success, lock = 1, error = null, url = null) {

    //默认错误回调
    error = (error == null) ? __error : error;

    //校验
    if (!__validate(url, lock, success, error))
      return;

    let header = {};
    header['Content-Type'] = __content_type._json;
    __ajax(url, __method._post, params, __data_type._json, __timeout, __encode, __decode,
      header, null, success, error, lock);
  },

  //post请求 -- 提交
  _post : function (params, success, url = null, lock = 1, error = null) {
    //默认错误回调
    error = (error == null) ? __error : error;

    url = (url == null)? _config.common_submit : url

    //校验
    if (!__validate(url, lock, success, error))
      return;

    __ajax(url, __method._post, params, __data_type._json, __timeout, __encode, null,
      null, null, success, error, lock);
  },
};

//导出
export default _request;
