
//弹出层
import { Loading } from 'element-ui';

let _layer = {

  //动态加载私有函数
  _load: function () {
    //console.log(loading);
    //Loading.service()
  },

  //关闭加载私有函数
  _load_close : function () {
    //Loading.service().close()
  },

};

export default _layer
