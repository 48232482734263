<template>
    <div class="wrapper m_wrap">
        <div class="m_wrap_top">
            <m_head></m_head>
            <!--        head end-->
            <m_menu></m_menu>
            <!--        m_menu end-->
            <div class="m_wrap_name">
                <img src="../../../assets/images/mobile/index/index_name.png" alt="">
            </div>
        </div>
<!--        m_wrap_top end-->
        <div class="m_banner">
            <div class="m_banner_wrap">
                <div class="m_index_tit">
                    <div class="m_index_tit_name">热门品牌活动<span>Hot Brand Events</span></div>
                    <div class="m_index_tit_more" style="display: none;">更多<i class="el-icon-caret-right"></i></div>
                </div>
                <el-carousel :interval="5000" arrow="always" height="150px">
                    <el-carousel-item
                            v-for="item in banners"
                            :key="item.id"
                            @click.native="item.link? linkTo(item.link): ''">
                        <img :src="item.thumb" :alt="item.name" />
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
<!--        m_banner end-->

        <div class="m_auto">
            <div class="m_auto_cont">
                <div class="m_auto_tit">城市车展</div>
                <p>City Auto Show</p>
                <el-button size="mini" style="border-radius: 11px;font-size: 12px;padding: 3px 7px; color: #2d5ce9;" @click="linkToLocal('auto')">GO ></el-button>
            </div>
            <div class="m_auto_cont">
                <div class="m_auto_tit">城市覆盖</div>
                <p>The City Covers</p>
                <el-button size="mini" style="border-radius: 11px;font-size: 12px;padding: 3px 7px; color: #fb8257;" @click="linkToLocal('operate')">GO ></el-button>
            </div>
        </div>
<!--        m_auto end-->

        <div class="m_brand_logo">
            <ul class="m_brand_logo_ul">
                <li v-for="(item,index) in brand_data" :key="index" @click="linkTo('https://www.yixingauto.com/new_pages/autoshow?activity_id=138&channel=1&tids=')">
                    <img :src="item.img" :alt="item.name">
                    <p>{{item.name}}</p>
                </li>
            </ul>
        </div>
<!--        m_brand_logo end-->
        <div class="m_video">
            <p>【师妹带你看车啦】广汽传祺-影豹</p>
            <video poster="../../../assets/images/mobile/index/cover_1.jpg"
                   :controls="videoOptions.controls"
                   preload="auto"
                   webkit-playsinline="true"
                   playsinline="true"
                   x-webkit-airplay="allow"
                   x5-video-player-type="h5"
                   width="100%">
                <source src="../../../assets/images/pc/video/yingbao.mp4" type="video/mp4">
            </video>
        </div>
<!--        m_video end-->

        <div class="m_news">
            <div class="m_index_tit" style="display: none;">
                <div class="m_index_tit_name">车市资讯<span>Car Market Information</span></div>
                <div class="m_index_tit_more" @click="linkToLocal('news')">更多<i class="el-icon-caret-right"></i></div>
            </div>
            <div class="m_index_news_list">
                <ul class="m_index_news_list_ul">
                    <router-link tag="li" :to="{name: 'info', query:{id:item.id}}" v-for="item in newsList" :key="item.id" class="m_index_news_list_ul_li">
                        <div class="m_index_news_list_ul_li_img">
                            <img :src="item.thumb" :alt="item.name">
                        </div>
                        <div class="m_index_news_list_ul_li_info">
                            <div class="m_index_news_list_ul_li_info_tit single_line">{{item.name}}</div>
                            <div class="m_index_news_list_ul_li_info_cont single_line">{{item.desc}}</div>
                        </div>
                    </router-link>
                </ul>
            </div>
        </div>
<!--        m_index_news end-->

        <div class="m_adv">
            <a :href="m_adv_3.link?m_adv_3.link:'javascript:void(0)'"
               :target="m_adv_3.link?'_blank':'_self'">
                <img :src="m_adv_3.thumb" :alt="m_adv_3.name">
            </a>
        </div>

        <div class="m_video">
            <p>本地车展来袭，快跟小姐姐来看车</p>
            <video poster="../../../assets/images/mobile/index/cover_2.jpg"
                   preload="metadata"
                   webkit-playsinline="true"
                   playsinline="true"
                   x-webkit-airplay="allow"
                   x5-video-player-type="h5"
                   :controls="videoOptions.controls" width="100%">
                <source src="../../../assets/images/pc/video/video_2.mp4" type="video/mp4">
            </video>
        </div>
        <!--        m_video end-->

        <div class="m_news">
            <div class="m_index_news_list">
                <ul class="m_index_news_list_ul">
                    <router-link tag="li" :to="{name: 'info', query:{id:item.id}}" v-for="item in newsList_industry" :key="item.id" class="m_index_news_list_ul_li">
                        <div class="m_index_news_list_ul_li_img">
                            <img :src="item.thumb" :alt="item.name">
                        </div>
                        <div class="m_index_news_list_ul_li_info">
                            <div class="m_index_news_list_ul_li_info_tit single_line">{{item.name}}</div>
                            <div class="m_index_news_list_ul_li_info_cont single_line">{{item.desc}}</div>
                        </div>
                    </router-link>
                </ul>
            </div>
        </div>
        <!--        m_index_news end-->

        <div class="m_adv">
            <a :href="m_adv_4.link?m_adv_4.link:'javascript:void(0)'"
               :target="m_adv_4.link?'_blank':'_self'">
                <img :src="m_adv_4.thumb" :alt="m_adv_4.name">
            </a>
        </div>

        <div class="m_video">
            <p>快来领取车展门票吧</p>
            <video
                   poster="../../../assets/images/mobile/index/cover_3.jpg"
                   preload="metadata"
                   webkit-playsinline="true"
                   playsinline="true"
                   x-webkit-airplay="allow"
                   x5-video-player-type="h5"
                   :controls="videoOptions.controls"
                   width="100%">
                <source src="../../../assets/images/pc/video/video_3.mp4" type="video/mp4">
            </video>
        </div>
        <!--        m_video end-->

        <div class="m_news">
            <div class="m_index_news_list">
                <ul class="m_index_news_list_ul">
                    <router-link tag="li" :to="{name: 'info', query:{id:item.id}}" v-for="item in newsList_flash" :key="item.id" class="m_index_news_list_ul_li">
                        <div class="m_index_news_list_ul_li_img">
                            <img :src="item.thumb" :alt="item.name">
                        </div>
                        <div class="m_index_news_list_ul_li_info">
                            <div class="m_index_news_list_ul_li_info_tit single_line">{{item.name}}</div>
                            <div class="m_index_news_list_ul_li_info_cont single_line">{{item.desc}}</div>
                        </div>
                    </router-link>
                </ul>
            </div>
        </div>
        <!--        m_index_news end-->

        <div class="m_number">
            <ul class="m_number_ul">
                <li v-for="(item,index) in companylist" :key="index">
                   <b><CountUp :endVal="item.num" :delay="delay" :options="options" />{{item.unit}}</b>
                   <p>{{item.info}}</p>
                </li>
            </ul>
        </div>
<!--        m_number end-->


        <m_btm></m_btm>

<!--        飘窗-->
        <div class="m_windows_img" v-if="is_show_window_l">
            <a :href="windowslist_l.link?windowslist_l.link:'javascript:void(0)'"
               :target="windowslist_l.link?'_blank':'_self'">
                <img :src="windowslist_l.thumb" :alt="windowslist_l.name">
            </a>
            <div class="el-icon-circle-close m_windows_img_close" @click="close_img('l')"></div>
        </div>

    </div>
</template>

<script>
import m_head from "../components/head";
import m_menu from "../components/menu";
import m_btm from "../components/bottom";
import lib_common from "../../../libs/lib_common";
import _cache_city from "../../../cache/_cache_city";
import _time from "../../../utils/_time";
import CountUp from 'vue-countup-v2';

let companylist = [
    {
        num: 3500,
        unit: '+万',
        info: '年广告投入'
    },
    {
        num: 80,
        unit: '城+',
        info: '覆盖城市'
    },
    {
        num: 300,
        unit: '场+',
        info: '举办活动'
    },

    {
        num: 560042,
        unit: '条+',
        info: '获取线索'
    }
];
let brand_data = [
    {
        name: '大众',
        img: 'https://www.yixingauto.com/uploads/logo/1638088110_9404.png'
    },
    {
        name: '丰田',
        img: 'https://www.yixingauto.com/uploads/logo/1638088116_3201.png'
    },
    {
        name: '比亚迪',
        img: 'https://www.yixingauto.com/uploads/logo/1638088102_3548.png'
    },
    {
        name: '奥迪',
        img: 'https://www.yixingauto.com/uploads/logo/1638088097_3485.png'
    },
    {
        name: '哈弗',
        img: 'https://www.yixingauto.com/uploads/logo/1638088123_2735.png'
    },
    {
        name: '吉利汽车',
        img: 'https://www.yixingauto.com/uploads/logo/1638088129_014.png'
    },
    {
        name: '特斯拉',
        img: 'https://www.yixingauto.com/uploads/logo/1638088161_7851.png'
    },
    {
        name: '宝马',
        img: 'https://www.yixingauto.com/uploads/logo/1638088101_5881.png'
    },
    {
        name: '领克',
        img: 'https://www.yixingauto.com/uploads/logo/1638088135_9442.png'
    },
    {
        name: '长安',
        img: 'https://www.yixingauto.com/uploads/logo/1638088107_9936.png'
    },

]

    export default {
        name: "index",
        components:{
            m_head,
            m_menu,
            m_btm,
            CountUp
        },
        data(){
            return{
                //跑马灯高度
                screenwidth: '140px',
                city_id: '',
                //图片轮播
                banners: [],
                //车展列表
                autolist: [],
                //新闻
                newsList: [],
                //
                companylist:companylist,
                //数字效果
                options: {
                    useEasing: true,
                    useGrouping: true,
                    separator: ',',
                    decimal: '.',
                    prefix: '',
                    suffix: '',
                },
                delay: 1000,
                //
                brand_data: brand_data,
                //video
                videoOptions: {
                    controls:true,
                },
                //
                newsList_industry: [],
                //
                is_show_window_l: true,
                windowslist_l: {},
                //
                newsList_flash: [],
                //
                m_adv_3: {},
                m_adv_4: {},
            }
        },
        methods:{
            banner_list() {
                let that = this;
                lib_common.advert("1", "5", this.city_id, function (res) {
                    that.banners = res.list;
                });
            }, //图片轮播


            auto_list(){
                let that = this;
                lib_common.advert("5","4", this.city_id, function (res) {
                    that.autolist = res.list;
                });
            },//展会列表

            news_list(){
                let that = this;
                lib_common.news('3', "1", "3", function (count,list) {
                    that.newsList = list;
                });
            },//新闻列表

            news_list_industry(){
                let that = this;
                lib_common.news('4', "1", "3", function (count,list) {
                    that.newsList_industry = list;
                });
            },//新闻列表

            news_list_flash(){
                let that = this;
                lib_common.news('5', "1", "3", function (count,list) {
                    that.newsList_flash = list;
                });
            },//新闻列表
            cen_adv_list(){
                let that = this;
                lib_common.advert("4", "9", this.city_id, function (res) {
                    that.m_adv_3 = res.list[3];
                    that.m_adv_4 = res.list[4];
                });
            },

            //飘窗广告
            windows_list() {
                let that = this;
                lib_common.advert("10", "2", this.city_id, function (res) {
                    that.windowslist_l = res.list[1];
                });
            },

            //跳转
            linkTo(link){
                window.open(link);
            },

            //跳转
            linkToLocal(name){
                this.$router.push({name: name})
            },

            //时间
            timedata(time){
                return _time._to_line_day(time*1000);
            },

            close_img(val){
                if(val == 'r'){
                    this.is_show_window_r = false;
                    return;
                }else if(val == 'l'){
                    this.is_show_window_l = false;
                    return;
                }
            },


        },
        mounted(){
        },
        created(){
            this.city_id = _cache_city.get();
            this.banner_list();
            this.auto_list();
            this.news_list();
            this.news_list_industry();
            this.news_list_flash();
            this.cen_adv_list();
            this.windows_list();
        }
    }
</script>

<style lang="less" scoped>
@import "../../../assets/style/font.css";
@import "../../../assets/style/mobile/index/index.less";
</style>
