<template>
    <div class="m_bottom">
        <p>
            Copyright © 2017 河北易之行网络科技有限公司<br>
            河北省石家庄市裕华区槐安东路141号<br>
            冀ICP备17016416号-1
        </p>
    </div>
</template>

<script>
    export default {
        name: "bottom",
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/mobile/components/bottom";
</style>
