<template>
   <div class="m_head">
       <div class="head_logo">
           <img src="../../../assets/images/logo_white.png" alt="易行汽车">
       </div>
<!--       head_logo end-->
       <div class="head_search">
           <el-input
                   placeholder="请输入关键字"
                   v-model="search_name"
                   clearable
           >
               <el-button slot="append" icon="el-icon-search" @click="getAuto()"></el-button>
           </el-input>
       </div>
<!--       head_search end-->
       <div class="head_city">
           <span @click="show_city">{{ city_name }}</span>
           <i class="el-icon-caret-bottom"></i>
       </div>
<!--       head_city end-->
<!--       城市弹框-->

       <el-dialog :visible.sync="citysdialog" fullscreen title="城市选择">
           <template v-for="(city_list, letter) in citys">
               <div :key="letter" class="m_head_list">
                   <div :id="'letter_' + letter" class="m_head_list_letter">{{ letter }}</div>
                   <ul class="m_head_list_cont">
                       <li
                               v-for="city in city_list"
                               :key="city.id"
                               @click="getCity(city.id)"
                       >
                           {{ city.name }}
                       </li>
                   </ul>
               </div>
           </template>
       </el-dialog>

   </div>
</template>

<script>
    import lib_common from "../../../libs/lib_common";
    import _config from "../../../common/_config";
    import _cache_city from "../../../cache/_cache_city";

    export default {
        name: "pc_head",
        data(){
            return{
                //城市弹框
                citysdialog: false,
                citys: [],
                city_id: 1301,
                city_name: "石家庄",

                search_name: ''
            }
        },
        created() {
            this.city_id = _cache_city.get();
           this.renderCity();
        },
        methods:{
            //显示弹框
            show_city(){
                this.citysdialog = !this.citysdialog;
            },
            renderCity() {
                lib_common.city(this.to_citys);
            },
            to_citys(data) {
                this.citys = data;
                for (let letter in data) {
                    for (let i in data[letter]) {
                        if (this.city_id == data[letter][i].id) {
                            this.city_name = data[letter][i].name;
                            return;
                        }
                    }
                }
            },

            getCity(id) {
                this.citysdialog = false;
                window.location.href =
                    window.location.protocol +
                    "//" +
                    window.location.host + '/app'+
                    _config.prefix +
                    "/home?city_id=" +
                    id;
            },

            getAuto(){

            }
        }
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/mobile/components/head";
</style>
