<template>
  <div>
    <slot name="pagination">
      <el-pagination
        @current-change="current_change"
        :current-page="current"
        layout="total, prev, pager, next, jumper"
        :total="total"
        :page-size="size"
        class="margin-top-15"
      >
      </el-pagination>
    </slot>
  </div>
</template>
<script>
export default {
  name: "pagination",
  props: {
    total: Number,
    current: Number,
    size: Number,
  },
  created() {
  },
  methods : {

    //页码改变
    current_change(page){
      this.$emit('current-change', page)
    }
  }
};
</script>
