<template>
    <div class="pc_bottom">
        <div class="pc_bottom_wrap pc_wrap">
            <div class="pc_bottom_wrap_t">
                <div class="pc_bottom_wrap_t_l">
                    <div class="pc_bottom_wrap_t_l_img">
                        <img src="../../../assets/images/logo_white.png" alt="">
                    </div>
                    <div class="pc_bottom_wrap_t_l_phone">
                        400-808-1710 <p>( 请于工作日9:00-18:00来电 )</p>
                    </div>
                </div>
                <div class="pc_bottom_wrap_t_r">
                    <div class="pc_bottom_wrap_t_r_ul">
                        <h3>关于我们</h3>
                        <ul>
                            <li v-for="(item,index) in aboutlist" :key="index">
                                <router-link :to="{name: 'about'}" target="_blank">{{item}}</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="pc_bottom_wrap_t_r_ul">
                        <h3>联系我们</h3>
                        <ul>
                            <li v-for="(item,index) in contactlist" :key="index">
                                <router-link :to="{name: 'contact'}" target="_blank">{{item}}</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="pc_bottom_wrap_t_r_ul">
                        <h3>友情链接</h3>
                        <ul>
                            <li v-for="item in linklist" :key="item.id">
                                <a :href="item.link" target="_blank">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="pc_bottom_wrap_t_r_ul">
                        <h3>意见反馈邮箱</h3>
                        <div class="pc_bottom_wrap_t_r_ul_div">
                            <p>wangxinpei@yzxauto.com</p>
                            <img src="../../../assets/images/ewm_wang.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="pc_bottom_wrap_b">
                <p v-if="isshow">
                    Copyright © 2017 河北易之行网络科技有限公司 | 备案/许可证号：
                    <a href="https://beian.miit.gov.cn/">冀ICP备17016416号-1</a><br />
                    地址：河北省石家庄市裕华区槐安东路141号 电话：0311-66561958
                </p>
                <p v-else>
                    Copyright © 2017 山东赤兔文化传媒有限公司 | 备案/许可证号：
                    <a href="https://beian.miit.gov.cn/">鲁ICP备18035235号-3</a><br />
                    地址：山东省济南市槐荫区经十西路担山村现代物流园信息楼4楼D区D046室
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import lib_common from "../../../libs/lib_common";

    let aboutlist = ['发展历程','业务版块','活动案例'];
    let contactlist = ['招商入驻','运营推广','官方客服'];
    export default {
        name: "bottom",
        data(){
            return{
                isshow: true,
                linklist: [],
                aboutlist:aboutlist,
                contactlist:contactlist
            }
        },
        methods:{
            link_list() {
                let that = this;
                lib_common.friend("1", "3", function (res) {
                    that.linklist = res.list;
                });
            },
        },
        created() {
            let base_url = window.location.host;
            if (base_url.indexOf("chitu") != -1) {
                this.isshow = false;
            };
            this.link_list()
        },
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/pc/components/bottom";
</style>
