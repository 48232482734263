<template>
  <div class="wrapper m_wrap m_operate">
      <m_head></m_head>
      <!--        head end-->
      <m_menu activeClassId="3"></m_menu>
      <!--        m_menu end-->
      <div class="m_operate_banner">
          <img src="../../../assets/images/mobile/operate/banner.png" alt="">
      </div>
<!--      m_operate_banner end-->
      <div class="m_operate_cont">
          <ul class="m_operate_cont_nav">
              <li v-for="(item,index) in tab_list"
                  :key="index"
                  @click="change_tabs(index)">
                  <img :src="item.icon" alt="">
              </li>
          </ul>

          <div class="m_operate_cont_item" v-if="isTabnav == 0">
<!--              展会-->
              <div class="m_operate_cont_item_tit" style="margin-bottom: 4%;">
                  单品推广案例
                  <p class="m_operate_cont_item_tit_span">单一品牌线上推广集客项目促进潜在消费者进店 提高转化率</p>
              </div>

              <div class="m_operate_auto" v-for="(item,index) in advBrandList" :key="index">
                  <p class="m_operate_auto_tit">{{item.name}}</p>
                  <img class="m_operate_auto_img" :src="item.thumb" :alt="item.name" @click="item.link? linkTo(item.link): ''">
              </div>

<!--              单品-->
              <div class="m_operate_cont_item_tit" style="margin-bottom: 4%;">
                  展会推广案例
                  <p class="m_operate_cont_item_tit_span">扩大地区展会项目曝光率 收集有效客源信息 提高到场率</p>
              </div>
              <ul class="m_operate_auto_tabs">
                  <li v-for="(item,index) in city_list"
                      :key="index"
                      @click="change_auto(item.id)"
                      :class="auto_id == item.id? 'cur' : ''"
                  >{{item.name}}</li>
              </ul>
              <div class="m_operate_auto" v-for="(item,index) in advAutoList" :key="index" v-if="auto_id == item.id">
                  <p class="m_operate_auto_tit">{{item.name}}</p>
                  <img class="m_operate_auto_img" :src="item.thumb" :alt="item.name" @click="item.link? linkTo(item.link): ''">
              </div>

          </div>

          <div class="m_operate_cont_item" v-if="isTabnav == 1">
              <div class="m_operate_range">
                  <img src="../../../assets/images/mobile/operate/range.png" alt="">
                  <img class="m_operate_range_p" src="../../../assets/images/mobile/operate/cont/range/range.png">
              </div>
              <div class="m_operate_range_data">
                  <div class="m_operate_range_data_cont">
                      <h3>重点辐射城市</h3>
                      <dl v-for="(item,index) in range_data" :key="index">
                          <dt>{{item.label}}</dt>
                          <dd>
                              <ul>
                                  <li v-for="(i,k) in item.value" :key="k">
                                      <h5>{{i.label}}</h5>
                                      <em>{{i.value}}</em>
                                      <span>万</span>
                                  </li>
                              </ul>
                          </dd>
                      </dl>
                  </div>
              </div>
          </div>

          <div class="m_operate_cont_item" v-if="isTabnav == 2">
             <div class="m_operate_channel">
                  <img src="../../../assets/images/mobile/operate/cont/channel/channel.png" alt="">
             </div>
          </div>

          <div class="m_operate_cont_item" v-if="isTabnav == 3">
              <div class="m_operate_adv">
                  <img src="../../../assets/images/mobile/operate/cont/adv/adv.png" alt="">
              </div>
          </div>

      </div>


      <m_btm></m_btm>
  </div>
</template>

<script>
    import m_head from "../components/head";
    import m_menu from "../components/menu";
    import m_btm from "../components/bottom";
    import lib_common from "../../../libs/lib_common";

    let tab_list = [
        {
            name: '合作案例',
            letters: 'Cooperation cases',
            bg_img: require('../../../assets/images/mobile/operate/operate_btn_1.png'),
            icon: require('../../../assets/images/mobile/operate/icon/icon_1.png')
        },
        {
            name: '覆盖范围',
            letters: 'Coverage',
            bg_img: require('../../../assets/images/mobile/operate/operate_btn_2.png'),
            icon: require('../../../assets/images/mobile/operate/icon/icon_2.png')

        },
        {
            name: '投放渠道',
            letters: 'Delivery channel',
            bg_img: require('../../../assets/images/mobile/operate/operate_btn_3.png'),
            icon: require('../../../assets/images/mobile/operate/icon/icon_3.png')

        },
        {
            name: '投放优势',
            letters: 'Launch advantage',
            bg_img: require('../../../assets/images/mobile/operate/operate_btn_4.png'),
            icon: require('../../../assets/images/mobile/operate/icon/icon_4.png')

        }
    ];
    let range_data = [
        {
            label: '华北',
            value: [
                {
                    label: '石家庄',
                    value: '1120.47'
                },
                {
                    label: '太原',
                    value: '539.1'
                },
                // {
                //     label: '北京',
                //     value: '2188.6'
                // }
            ]
        },
        {
            label: '华中',
            value: [
                {
                    label: '荆州',
                    value: '523.12'
                },
                {
                    label: '黄冈',
                    value: '588.27'
                },
            ]
        },
        {
            label: '华东',
            value: [
                {
                    label: '宜春',
                    value: '500.77'
                },
                {
                    label: '衢州',
                    value: '227.6'
                },
            ]
        },
        {
            label: '华南',
            value: [
                {
                    label: '广州',
                    value: '1881.06'
                },
                {
                    label: '桂林',
                    value: '493.11'
                },
            ]
        },
        {
            label: '西南',
            value: [
                {
                    label: '重庆',
                    value: '3212.43'
                },
                {
                    label: '成都',
                    value: '2119.2'
                },
            ]
        },
        {
            label: '西北',
            value: [
                {
                    label: '兰州',
                    value: '438.43'
                },
                {
                    label: '银川',
                    value: '285.9'
                },
            ]
        }
    ];

    let city_list = [
        {
            id: 81,
            city_id: '1301',
            name: '石家庄市'
        },
        {
            id: 80,
            city_id: '4401',
            name: '广州市'
        },
        {
            id: 79,
            city_id: '1101',
            name: '北京市'
        }
    ]

    export default {
        name: "operate",
        components:{
            m_head,
            m_menu,
            m_btm
        },
        data(){
            return{
                //tabs切换
                tab_list: tab_list,
                isTabnav: 0,


                advAutoList: [],
                advBrandList: [],

                //范围数据
                range_data:range_data,

                //
                city_list: city_list,
                auto_id: 81,
            }
        },
        methods:{
            //切换tab
            change_tabs(id){
                this.isTabnav = id
            },

            //展会切换城市
            change_auto(id){
                this.auto_id = id
            },
            //展会推广
            adv_auto_list(){
                let that = this;
                lib_common.advert('8', "3", this.city_id, function (res) {
                    that.advAutoList = res.list;
                });
            },
            //单品
            adv_brand_list(){
                let that = this;
                lib_common.advert('9', "1", this.city_id, function (res) {
                    that.advBrandList = res.list;
                });
            },

            //跳转
            linkTo(link){
                window.open(link);
            },
        },
        created() {
            this.adv_auto_list();
            this.adv_brand_list();
        }
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/mobile/operate/operate";
</style>

