<template>
   <div class="wrapper m_wrap m_about">
       <m_head></m_head>
       <!--        head end-->
       <m_menu activeClassId="5"></m_menu>
       <!--        m_menu end-->

       <div class="m_about_banner">
           <img src="../../../assets/images/mobile/about/banner.png" alt="">
       </div>
<!--       m_about_banner end-->
       <div class="m_about_cont">
           <ul class="m_about_tabs">
               <li v-for="(item,index) in tab_list"
                   :key="index" :class="isTabnav == index?'cur': ''"
                   @click="change_tabs(index)"
               >{{item}}</li>
           </ul>
           <!--           m_about_tabs_nav end-->

           <div class="m_about_cont_item" v-if="isTabnav == 0">


               <div class="m_about_about">
                   <div class="m_about_about_tit">车生活   易在途   行其远</div>
                   <div class="m_about_about_cont">
                       河北易之行网络科技有限公司是一家服务汽车领域的新兴互联网公司。<br>
                       主创团队于2010年进入汽车行业，2017年正式成立河北易之行网络科技有限公司，2020年成立山西易之行、湖北易之行分公司，2021年成立广东易之行分公司，2022年成立河北果然好分公司。团队近80人，包含运营团队、销售团队、电销团队、线上支持和服务团队。是一家集方案策划，线上推广、落地执行为一体的综合能力强的互联网企业。<br>
                       截止目前，公司业务范围已经扩展至河北，山西，广东，内蒙，湖北，四川，青海，甘肃，江西等多省份超六十个城市。旗下业务内容涉及车展、汽车品牌团购、线上推广集客、二手车、售后续保等各个方面，与市场主流的电台、报业、广告代理商均有深度合作。
                   </div>
               </div>

               <ul class="m_about_num">
                   <li v-for="(item,index) in companylist" :key="index">
                       <b><CountUp :endVal="item.num" :delay="delay" :options="options" />{{item.unit}}</b>
                       <p>{{item.info}}</p>
                   </li>
               </ul>

           </div>

           <div class="m_about_cont_item" v-if="isTabnav == 1">
               <div class="m_about_service_tit">易行汽车五大业务板块</div>

               <ul class="m_about_service">
                   <li v-for="(item,index) in service_list" :key="index">
                       <p>0{{item.id}}</p>
                       <p>{{item.name}}</p>
                   </li>
               </ul>
               <p class="m_about_service_info">2022年公司将进一步提高服务水平，在汽车领域纵向深度挖掘，把更多得实惠带给每个城市的准车主朋友们，更好地服务每个城市的汽车经销商，致力于做汽车流通行业的高效组织者，也期待和整个汽车行业上下游企业、更多的汽车媒体、平台取得良好的合作！</p>
           </div>

           <div class="m_about_cont_item" v-if="isTabnav == 2">

               <div class="m_about_join_img">
                   <img src="../../../assets/images/mobile/about/join/join_1.png" alt="">
                   <img src="../../../assets/images/mobile/about/join/join_2.png" alt="">
               </div>

               <div class="m_about_join_tit">
                   <img src="../../../assets/images/mobile/about/join/join_3.png" alt="">
                   <span>加入我们</span>
               </div>
               <div class="m_about_join">
                   <el-collapse v-model="active_recruitName">
                       <el-collapse-item
                               :title="items.name"
                               :name="items.id"
                               v-for="items in recruitDate"
                               :key="items.id"
                       >
                           <div class="m_about_join_info">
                               <h5>岗位职责:</h5>
                               <p v-for="(item,index) in items.duty" :key="index">{{item}}</p>
                           </div>
                           <div class="m_about_join_info">
                               <h5>任职要求:</h5>
                               <p v-for="(ask,index) in items.ask" :key="index">{{ask}}</p>
                           </div>
                       </el-collapse-item>
                   </el-collapse>
               </div>
           </div>

       </div>


<!--       m_about_tabs end-->
        <m_btm></m_btm>

   </div>

</template>

<script>
    import m_head from "../components/head";
    import m_menu from "../components/menu";
    import m_btm from "../components/bottom";
    import CountUp from 'vue-countup-v2';

    let tab_list = ['企业简介','企业服务','加入我们'];
    //招聘
    let recruit_data = [
        {
            id: '1', name: '信息流优化师',
            duty: [
                '1、负责信息流平台广告的投放及优化，监控效果;',
                '2、制定计划和有效管理账户素材，对文案和素材有一定的优化能力;',
                '3、定期对账户投放方式，以及相关问题，做出判断，及时调整账户的投放方式;',
                '4、对竞价投放的页面，有提出优化和修改的能力;',
            ], ask:[
                '1、专科及以上学历，有2年以上头条系信息流投放工作经验优先;',
                '2、对数据有高度敏感性，有一定的数据分析能力，熟悉数据报表中各个环节内容以及关系，通过数据分析，挖掘产品投放增长点，优化前端投放成本，提高转化率;',
                '3、具备创新思维，积极主动接受新鲜事物，敢于接受工作挑战;',
            ]
        },
        {
            id: '2', name: '广告渠道销售经理',
            duty: [
                '1、面向全国展会行业，以互联网集客产品为载体，开发有品牌推广诉求的重点企业客户;',
                '2、根据客户品牌推广诉求，通过研究行业用户的习惯，以行业数据为载体，为大客户提供最优的集客营销方案;',
                '3、通过行业深挖，开拓更多渠道资源，通过研究行业特点，能够为行业内客户提供适配的品牌推广策略和方案;'
            ], ask:[
                '1、有互联网媒体（今日头条、快手、腾讯广告、百度等）或传统媒体广告销售经验者优先录用;',
                '2、有汽车行业相关工作经验优先录用;',
                '3、能接受出差;',
                '4、服务过品牌客户并有成功案例优先考虑;',
                '5、具备良好的学习意识，能够主动学习百度及其他媒体产品，掌握全案营销的思维;',
                '【人才画像】',
                '1、销售欲望强烈;',
                '2、注重个人形象',
                '3、属于商务型谈判高手',
                '4、思维活跃',
                '5、人际圈子广',
                '【加入我们您将获得什么】',
                '1、完善的内部培训体系，可以全面学习了解大搜、信息流及其他新媒体推广工具，并全面提升品牌营销思维;',
                '2、领先的薪酬水平，行业内绝对领先;',
                '3、丰富的员工激励，广阔的职业发展;',
                '薪酬福利：无责底薪+提成+五险+每月6天公休+国家法定节假日+带薪年休假+完善的福利制度+广阔的职业发展空间;'
            ],
        },
        {
            id: '3', name: '汽车行业大客户经理',
            duty: [
                '1、负责汽车厂家、区域、集团的大客户开发、维护工作;',
                '2、宣传并推广公司产品服务模式，提升品牌及产品认知度;',
                '3、对总体销售进行规划，优化产品销售结构，完成公司的销售目标;',
                '4、发掘并跟踪潜在客户，积累客户资源，为大客户提供互联网整合营销方案;',
            ], ask:[
                '1、具备目标管理能力和良好的社交沟通能力;',
                '2、从事过汽车之家、易车、懂车帝、汽车媒体相关岗位从业经验，有汽车4S店资源者优先;',
                '3、有梦想、有责任、有担当，挑战高薪;',
            ]
        },
        {
            id: '4', name: '汽车后市场营销辅导师',
            duty: [
                '1、负责汽车4S店售后流失客户保养套餐团购会驻店辅导工作;',
                '2、协助店内制定活动套餐，针对活动进行话术、流程培训;',
                '3、管控活动落地现场环节流程，处理现场突发状况，达成最终业绩指标;'
            ], ask:[
                '1、汽车或市场营销相关专业大专以上学历;',
                '2、2年以上汽车行业市场从业经验，有活动主持和培训讲师经验（有4S店展厅经理、销售经理、市场经理、内训师经验优先考虑）;',
                '3、有大型活动策划与执行经验，具有机敏的控制现场的能力;',
                '4、具有优秀的沟通能力、组织协调能力以及总结能力;',
                '5、具备良好的个人形象和素养，吃苦耐劳、抗压性强;',
                '6、可适应短期出差;',
            ]
        },
        {
            id: '5', name: '渠道招商经理',
            duty: [
                '1、负责所辖汽车品牌商家（4S店/经销商集团）资源的开拓与维护;',
                '2、负责公司车展、汽车团购等业务招商与洽谈，完成销售任务;',
                '3、负责C端客户的联系与维护;',
                '4、负责现场活动执行与支持工作'
            ], ask:[
                '1、有一年以上招商、渠道及销售经验，熟悉车展、汽车行业优先;',
                '2、具备较强的沟通表达能力、应变能力及解决问题能力;',
                '3、性格开朗、善于交谈、积极主动、乐于学习;',
                '4、有较强的成就欲望及抗压能力;'
            ]
        },
        {
            id: '6', name: '电销经理',
            duty: [
                '1、带领团队，对团队成员进行培训、辅导，达成业绩和产能目标;',
                '2、基于业务目标为团队制订可行的策略与执行方案，推动实施，对团队业绩和产能负责;',
                '3、确保团队可持续性建设，人员质量、架构、稳定性可支撑业务目标的达成;',
            ], ask:[
                '1、具备较好的营销思维，业绩导向，对指标达成有强烈欲望;',
                '2、2年及以上的营销团队的管理经验;',
                '3、具备营销行业专业知识，了解行业运营模式、风险、指标及管理知识;',
                '4、具有团队意识、协同精神，良好的逻辑思维能力，深度分析解决问题的能力;'
            ]
        }
    ];
    //服务
    let service_list = [
        {
            id: 1,
            name: '以自营车展业务为主的易行车展板块',
            info: '作为公司最早开展的业务之一，易行车展已经拓展至全国超六十个城市，举办活动超过300场，无论从前期筹划、宣传、现场气氛，还是在到场率、订车量等方面，都被广大4S店及品牌区域充分认可，在当地形成了良好的口碑。'
        },
        {
            id: 2,
            name: '以线上推广代运营为主的易迅通板块',
            info: '依托于长期以来在线上移动互联网广告投放方面积累的大量经验，公司延伸发展了线上推广业务，包括展会活动、汽车单品、二手车、房地产、餐饮加盟、美食团购等方面，年广告投入超过3500万，被众多广告投放商认可。'
        },
        {
            id: 3,
            name: '以二手车线索获客为主的果然好板块',
            info: '这是易行汽车在面对疫情压力，整合线上推广领域积累的大量经验，以此为基础开展的全新业务板块。2022年成立河北果然好分公司，专门处理二手车方面的业务，包括：二手车全国范围推广集客、二手车线索包、二手车区域代理商等，目前已经合作超30家车商。'
        },
        {
            id: 4,
            name: '以4S店售后服务为主的养车客板块',
            info: '根据社会发展现状汽车后市场的用户细分需求，我们建立了一个为车主提供一站式4S店服务的o2o汽车维保服务平台——养车客。主要解决4S店基盘客户流失、保养客单价降低、套餐覆盖率不足、客户活跃度下降等问题，有效促进老客户回厂保养。'
        },
        {
            id: 5,
            name: '以4S店新车团购为主的特卖会板块',
            info: '在展会活动的基础上，公司进一步开展了针对单个品牌经销商的新车团购活动，从活动组织、推广宣传、落地执行各方面统筹安排，在提升客户到场，推动客单成交方面表现出色，先后与多家经销商集团建立了长期良好的合作关系。'
        }
    ];
    //
    let companylist = [
        {
            num: 3500,
            unit: '+万',
            info: '年广告投入'
        },
        {
            num: 80,
            unit: '城+',
            info: '覆盖城市'
        },
        {
            num: 300,
            unit: '场+',
            info: '举办活动'
        },

        {
            num: 560042,
            unit: '条+',
            info: '获取线索'
        }
    ];

    export default {
        name: "about",
        components:{
            m_head,
            m_menu,
            m_btm,
            CountUp
        },
        data(){
            return{
                //tab
                isTabnav: 0,
                tab_list: tab_list,
                //招聘
                active_recruitName: '1',
                recruitDate: recruit_data,

                //运营
                service_list:service_list,
                //服务
                active_ServiceName: 1,
                //

                companylist:companylist,
                //数字效果
                options: {
                    useEasing: true,
                    useGrouping: true,
                    separator: ',',
                    decimal: '.',
                    prefix: '',
                    suffix: '',
                },
                delay: 1000,
            }
        },
        methods:{
            change_tabs(id){
              this.isTabnav = id;
            },
        }
    }
</script>

<style scoped lang="less">
@import "../../../assets/style/font.css";
@import "../../../assets/style/mobile/about/about";
</style>
<style lang="less">
.m_about_join{
    .el-collapse-item__header{
        font-size: 15px;
        font-weight: bold;
        padding: 0 3%;
        box-sizing: border-box;
    }
    .el-collapse-item__content{
        font-size: 14px;
        padding: 0 3% 3%;
        box-sizing: border-box;
        text-align: justify;
    }
}
</style>
