
//
let __keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

let __utf8_encode = function (s) {
    s = s.replace(/\r\n/g,"\n");
    let t = "";
    let l = s.length;
    for (let n = 0; n < l; n++)
    {
        let c = s.charCodeAt(n);
        if (c < 128)
        {
            t += String.fromCharCode(c);
        }
        else if((c > 127) && (c < 2048))
        {
            t += String.fromCharCode((c >> 6) | 192);
            t += String.fromCharCode((c & 63) | 128);
        }
        else
        {
            t += String.fromCharCode((c >> 12) | 224);
            t += String.fromCharCode(((c >> 6) & 63) | 128);
            t += String.fromCharCode((c & 63) | 128);
        }

    }
    return t;
};

let __utf8_decode = function(t) {
    let s = "";
    let chr1, chr2, chr3;
    let i = 0;
    let l = t.length;
    while ( i < l )
    {
        chr1 = t.charCodeAt(i);
        if (chr1 < 128)
        {
            s += String.fromCharCode(chr1);
            i++;
        }
        else if((chr1 > 191) && (chr1 < 224))
        {
            chr2 = t.charCodeAt(i+1);
            s += String.fromCharCode(((chr1 & 31) << 6) | (chr2 & 63));
            i += 2;
        }
        else
        {
            chr2 = t.charCodeAt(i+1);
            chr3 = t.charCodeAt(i+2);
            s += String.fromCharCode(((chr1 & 15) << 12) | ((chr2 & 63) << 6) | (chr3 & 63));
            i += 3;
        }
    }
    return s;
};

//base64加解密工具
let _base64 = {
    _encode : function (input) {
        let output = "";
        let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        input = __utf8_encode(input);
        let i = 0;
        let l = input.length;
        while (i < l)
        {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2))
            {
                enc3 = enc4 = 64;
            }
            else if (isNaN(chr3))
            {
                enc4 = 64;
            }
            output += __keyStr.charAt(enc1) + __keyStr.charAt(enc2) + __keyStr.charAt(enc3) + __keyStr.charAt(enc4);
        }
        return output;
    },

    //解密
    _decode : function (input) {
        let output = "";
        let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        let i = 0;
        let l = input.length;
        while (i < l)
        {
            enc1 = __keyStr.indexOf(input.charAt(i++));
            enc2 = __keyStr.indexOf(input.charAt(i++));
            enc3 = __keyStr.indexOf(input.charAt(i++));
            enc4 = __keyStr.indexOf(input.charAt(i++));
            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;
            output += String.fromCharCode(chr1);
            if (enc3 !== 64)
            {
                output += String.fromCharCode(chr2);
            }
            if (enc4 !== 64)
            {
                output += String.fromCharCode(chr3);
            }
        }
        output = __utf8_decode(output);
        return output;
    }
};

//导出函数
export default _base64;
